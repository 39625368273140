import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import queryString from "query-string";
import { EntityTypes } from "../../../../types/enums";
import { performGroupByTranslations } from "../../../../services/filter-service";
export function WidgetTable({
  chartData,
  properties,
  history,
  widget,
  setActiveIndex,
}) {
  const handleTableClick = (e) => {
    const qsObject = {
      groupBy: `${properties.groupBy}//${performGroupByTranslations(
        properties.groupBy,
        e.name
      )}`,
      filterId: widget.dashboardWidgetFilters[0].entityFilterId,
    };
    if (_.toLower(properties.entityType) == _.toLower(EntityTypes.Event)) {
      history.push(`/events?${queryString.stringify(qsObject)}`);
    }
    if (
      _.toLower(properties.entityType) == _.toLower(EntityTypes.MonitoringEvent)
    ) {
      history.push(
        `/monitoring?view=events&${queryString.stringify(qsObject)}`
      );
    }
    if (_.toLower(properties.entityType) == _.toLower(EntityTypes.Action)) {
      history.push(`/actions?${queryString.stringify(qsObject)}`);
    }
    if (_.toLower(properties.entityType) == _.toLower(EntityTypes.Incident)) {
      history.push(`/incidents?${queryString.stringify(qsObject)}`);
    }
    if (
      _.toLower(properties.entityType) ==
      _.toLower(EntityTypes.MonitoringResult)
    ) {
      history.push(
        `/monitoring?view=results&${queryString.stringify(qsObject)}`
      );
    }
    if (
      _.toLower(properties.entityType) == _.toLower(EntityTypes.InspectionEvent)
    ) {
      history.push(`/inspections?${queryString.stringify(qsObject)}`);
    }
    if (_.toLower(properties.entityType) == _.toLower(EntityTypes.EnergyLog)) {
      history.push(`/energy-logs?${queryString.stringify(qsObject)}`);
    }
  };
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 20 }}></TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.orderBy(chartData, "value", "desc").map((entry, index) => (
            <TableRow
              className="chart-legend-row"
              key={index}
              onClick={() => handleTableClick(entry)}
              onMouseEnter={(e) => setActiveIndex(index)}
              onMouseLeave={() => setActiveIndex(null)}
            >
              <TableCell>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: entry.color,
                  }}
                ></div>
              </TableCell>
              <TableCell>{entry.name}</TableCell>
              <TableCell>{entry.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
