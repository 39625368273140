export const PORTAL_LOAD = "PORTAL_LOAD";
export const PORTAL_LOAD_SUCCESS = "PORTAL_LOAD_SUCCESS";
export const PORTAL_LOAD_FAIL = "PORTAL_LOAD_FAIL";
export function loadPortal(url) {
  return {
    type: PORTAL_LOAD,
    payload: {
      request: {
        url: "/organizationPortal/details?url=" + url,
      },
    },
  };
}
