import * as React from "react";
import cn from "classnames";
import Container from "../container/container";
import PageHeader from "./page-header";

interface Props {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  subTitle?: string;
  options?: React.ReactNode;
}

const PageContent: React.FC<Props> = ({
  className,
  children,
  title,
  subTitle,
  options,
}) => {
  const classes = cn("page-content", className);

  return (
    <div className={classes}>
      <Container>
        {(title || subTitle || options) && (
          <PageHeader title={title} subTitle={subTitle} options={options} />
        )}
        {children}
      </Container>
    </div>
  );
};

PageContent.displayName = "Page.Content";

export default PageContent;
