import React, { useState, useEffect } from "react";
import { Grid, Card } from "components/lynx-components";
import { getNotificationTemplates } from "../../../../services/notification-template-serivce";
import { DataGridPro } from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import { NotificationTemplateModal } from "./notification-template-modal";
import Backdrop from "@mui/material/Backdrop";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { hasAnyEventRole, roleMatch } from "../../../../actions/auth";
import { UserRoles } from "../../../../types/enums";
export function NotificationSettings(props) {
  const notificationColumns = [
    {
      field: "Actions",
      headerName: "",
      width: 50,
      type: "actions",
      resizable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditNotification(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 300,
      type: "string",
    },
    {
      field: "description",
      headerName: "Description",
      width: 600,
      type: "string",
    },
    {
      field: "notificationEnabled",
      headerName: "Notification Enabled?",
      width: 200,
      type: "boolean",
    },
    {
      field: "emailEnabled",
      headerName: "Email Enabled?",
      width: 200,
      type: "boolean",
    },
  ];

  const [loading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const history = useHistory();
  useEffect(() => {
    getNotificationTemplates().then((res) => {
      setTemplates(res.data);
      setIsLoading(false);
    }, []);
  }, []);

  const handleEditNotification = (template) => {
    setSelectedTemplate(template);
    setShowEditModal(true);
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Notification Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">
                Notification Settings
              </Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      {hasAnyEventRole() && (
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Event Notifications
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                Configure event notifications here.
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card>
              <div style={{ height: 630, width: "100%" }}>
                <DataGridPro
                  rows={templates.filter(
                    (x) => _.toLower(x.entityType) == "event"
                  )}
                  columns={notificationColumns}
                  disableMultipleSelection={true}
                  loading={loading}
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
      )}
      {roleMatch([UserRoles.MonitoringUser]) && (
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Monitoring Event Notifications
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                Configure monitoring event notifications here.
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card>
              <div style={{ height: 630, width: "100%" }}>
                <DataGridPro
                  rows={templates.filter(
                    (x) => _.toLower(x.entityType) == "monitoringevent"
                  )}
                  columns={notificationColumns}
                  disableMultipleSelection={true}
                  loading={loading}
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
      )}
      {showEditModal && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showEditModal}
          onClose={handleModalClose}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <NotificationTemplateModal
            template={selectedTemplate}
            handleModalClose={handleModalClose}
            handleClose={(t) => {
              setTemplates(t);
              handleModalClose();
            }}
          />
        </Dialog>
      )}
    </Grid>
  );
}
