import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Card, Dimmer, Form, Grid } from "components/lynx-components";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import PrintIcon from "@mui/icons-material/Print";
import { Divider } from "@mui/material";
import Chip from "@mui/material/Chip";
import localforage from "localforage";
import moment from "moment";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import lynxColors from "../../../modules/lynxColors";
import { getAssetLookups } from "../../../services/assets";
import { dateUtil } from "../../../services/date-util";
import { getEquipment } from "../../../services/equipment-service";
import { createGuid } from "../../../services/events";
import {
  createInspection,
  getInspection,
  updateInspection,
} from "../../../services/inspection-event-service";
import {
  getErrorFieldArray,
  getFullStateFromInspectionDef,
  getInspectionForms,
} from "../../../services/inspection-form-service";
import {
  assetStore,
  equipmentStore,
  eventSyncStore,
  inspectionFormStore,
} from "../../../services/offlineDataService";
import {
  CustomFieldTypes,
  EntityTypes,
  InspectionFormTypes,
} from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import { EquipmentModal } from "../equipment/equipment-modal";
import { validationService } from "./../../../services/validation";
import { AttachmentViewer } from "./../../attachment-viewer";
import { SearchableSelect } from "./../../form-controls/searchable-select";
import { InspectionFormField } from "./inspection-form-field";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useOnlineStatus from "hooks/useOnlineStatus";
import useAlert from "hooks/useAlert";

export function InspectionForm(props) {
  const intialForm = {
    assetId: "",
    assetIdError: "",
    inspectionDate: moment().format("YYYY-MM-DD"),
    inspectionDateError: "",
    completedDateTime: "",
    assignedToUserId: "",
    description: "",
    inspectionFormId: "",
  };
  const [selectedInspectionForm, setSelectedInspectionForm] = useState(null);
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [inspectionExpanded, setInspectionExpanded] = useState(true);
  const [formState, setFormState] = useState(intialForm);
  const [inspectionState, setInspectionState] = useState({});
  const [assets, setAssets] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [inspectionForms, setInspectionForms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inspection, setInspection] = useState({});
  const [equipment, setEquipment] = useState([]);
  const [inspectionFormsLoading, setInspectionFormsLoading] = useState(true);
  const [showChangeWarning, setShowChangeWarning] = useState(null);
  const [showAddEquipment, setShowAddEquipment] = useState(false);
  const [inspectionFormTouched, setInspectionFormTouched] = useState(false);
  const dimensions = useWindowDimensions();
  const isOffline = !useOnlineStatus();
  const { showAlert } = useAlert();

  const formFields =
    selectedInspectionForm &&
    selectedInspectionForm.inspectionFormFields.filter(
      (x) =>
        _.toLower(x.type) != _.toLower(CustomFieldTypes.Paragraph) &&
        _.toLower(x.type) != _.toLower(CustomFieldTypes.Divider)
    );
  const isExistingInspection = () => {
    return props.match.params.inspectionId;
  };
  const formRef = useRef();

  const { users } = useSelector((state) => state.lookups);

  useEffect(() => {
    loadInspectionForms();
  }, []);

  useEffect(() => {
    loadLookups();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (
      !isExistingInspection() &&
      !_.isEmpty(selectedInspectionForm) &&
      !inspectionFormsLoading
    ) {
      setupInitialInspectionState();
    }
  }, [inspectionFormsLoading, selectedInspectionForm]);

  useEffect(() => {
    if (isExistingInspection() && !inspectionFormsLoading) {
      loadInspection();
    }
  }, [inspectionFormsLoading]);

  useEffect(() => {
    if (!_.isEmpty(inspection) && !_.isEmpty(selectedInspectionForm)) {
      setFormStatesFromInspection();
    }
  }, [inspection, selectedInspectionForm]);

  const loadInspectionForms = () => {
    getInspectionForms()
      .then((res) => {
        setInspectionForms(res.data);
        localforage.setItem(inspectionFormStore, res.data);
        setInspectionFormsLoading(false);
      })
      .catch(() => {
        localforage.getItem(inspectionFormStore).then((data) => {
          setInspectionForms(data);
          setInspectionFormsLoading(false);
        });
      });
  };

  const loadInspection = () => {
    getInspection(props.match.params.inspectionId).then((res) => {
      var inspForm = inspectionForms.find(
        (x) => x.id == res.data.inspectionFormId
      );
      setSelectedInspectionForm(inspForm);
      setInspection(res.data);
      setIsLoading(false);
    });
  };

  const setFormStatesFromInspection = () => {
    // set new inspeciton state
    let newInspectionState = getFullStateFromInspectionDef(formFields);
    if (inspection.keyValuePairs) {
      var keyValuePairsObj = JSON.parse(inspection.keyValuePairs);
      newInspectionState = { ...newInspectionState, ...keyValuePairsObj };
    }
    setInspectionState(newInspectionState);
    setFormState({
      assetId: inspection.assetId,
      assignedToUserId: inspection.assignedToUserId,
      description: inspection.description,
      inspectionDate: inspection.inspectionDate,
      inspectionFormId: inspection.inspectionFormId,
      inspectionNumber: inspection.inspectionNumber,
      status: inspection.status,
      equipmentId: inspection.equipmentId,
    });
  };

  const setupInitialInspectionState = () => {
    const fullState = getFullStateFromInspectionDef(formFields);
    setInspectionState(fullState);
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };
  const handleConfirmInspectionFormChange = () => {
    const inspectionFormId = showChangeWarning;
    setFormState({ ...formState, inspectionFormId: inspectionFormId });
    if (inspectionFormId) {
      var inspectionForm = inspectionForms.find(
        (x) => x.id == inspectionFormId
      );
      setSelectedInspectionForm(inspectionForm);
    } else {
      setSelectedInspectionForm(null);
    }
    setInspectionState({});
    setShowChangeWarning(null);
    setInspectionFormTouched(false);
  };
  const handleInspectionFormChange = (e) => {
    if (inspectionFormTouched) {
      setShowChangeWarning(e.target.value);
      return;
    }
    handleInputChange(e);
    const value = e.target.value;
    if (value) {
      var inspectionForm = inspectionForms.find((x) => x.id == value);
      setSelectedInspectionForm(inspectionForm);
    } else {
      setSelectedInspectionForm(null);
    }
    setInspectionState({});
  };
  const handleInputChange = (e) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.value;

    _.set(newState, name, value);
    if (name == "assetId" || name == "inspectionFormId") {
      newState.equipmentId = "";
    }
    setFormState(newState);
  };
  const handleInspectionInputChange = (e, sigName, sigInput) => {
    let newState = { ...inspectionState };

    let name = sigName ?? e.target.name;
    const editedField = formFields.find((x) => x.name == name);
    let value = sigName
      ? sigInput
      : e.target.type == "checkbox"
      ? e.target.checked
      : editedField && editedField.type == CustomFieldTypes.MultiSelect
      ? e.target.value.join("|")
      : e.target.value;
    _.set(newState, name, value);

    setInspectionState(newState);

    setInspectionFormTouched(true);
  };
  const loadLookups = () => {
    getEquipment({ pageSize: 1000 })
      .then((res) => {
        setEquipment(res.data);
        localforage.setItem(equipmentStore, res.data);
      })
      .catch(() => {
        localforage.getItem(equipmentStore).then((data) => {
          setEquipment(data);
        });
      });

    getAssetLookups()
      .then((res) => {
        setAssets(res.data);
        localforage.setItem(assetStore, res.data);
      })
      .catch(() => {
        localforage.getItem(assetStore).then((data) => {
          setAssets(data);
        });
      });

    setIsLoading(false);
  };

  const handlePrintClick = useReactToPrint({
    content: () => formRef.current,
  });

  const getFormButtons = () => {
    return (
      <>
        {formState.status != "Completed" ? (
          <>
            <Button
              onClick={handleSubmitInspection}
              variant="contained"
              className="float-right"
            >
              Submit
            </Button>
            <Button
              onClick={handleSaveDraft}
              variant="contained"
              color="success"
              className="mr-2"
            >
              Save As Draft
            </Button>
          </>
        ) : (
          <Button
            onClick={handleSubmitInspection}
            variant="contained"
            color="success"
            className="mr-2"
          >
            {"Update Completed Inspection"}
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={handlePrintClick}
          startIcon={<PrintIcon />}
        >
          Print
        </Button>
      </>
    );
  };

  const handleSubmitInspection = () => {
    if (!validateSubmitDataForSave()) {
      return;
    }
    setIsSaving(true);
    let inspectionEventToSave = validationService.unsetErrors(
      formState,
      "assetIdError",
      "inspectionTypeIdError"
    );
    const errorFields = getErrorFieldArray(formFields);
    let inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    const formHtml = formRef.current.innerHTML;
    const dtoToSave = {
      ...inspectionEventToSave,
      assetId: inspectionEventToSave.assetId,
      keyValuePairs: JSON.stringify(inspectionFormValuesToSave),
      completedFormHtml: formHtml,
      isSubmit: true,
    };
    const formToSave = createFormData(dtoToSave, uploadedFiles);
    if (!isExistingInspection()) {
      createInspection(formToSave, true)
        .then((res) => {
          setInspection(res.data);
          showAlert("success", "Inspection saved.");
          setIsSaving(false);
          handleNavigateTo(null, `/inspections/${res.data.id}`);
        })
        .catch((err) => {
          handleCreateCatch(err, dtoToSave, uploadedFiles);
        });
    } else {
      updateInspection(props.match.params.inspectionId, dtoToSave, true)
        .then((res) => {
          showAlert("success", "Inspection saved.");
          setIsSaving(false);
          setInspection(res.data);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const handleCreateCatch = (err, dtoToSave, uploadedFiles) => {
    if (err.response) {
      showAlert("error", err.response.data.message);
      setIsSaving(false);
      return;
    }
    localforage.getItem(eventSyncStore).then((res) => {
      let data = [];
      if (res !== null && res !== undefined) {
        data = [...res];
      }
      let guid = createGuid();
      data.push({
        id: guid,
        type: "inspection-event",
        form: { ...dtoToSave },
        attachments: [...uploadedFiles],
      });
      localforage
        .setItem(eventSyncStore, data)
        .then(() => {
          showAlert("success", "Inspection saved for syncing.");
          handleNavigateTo(null, "/inspections");
        })
        .catch((err) => {
          showAlert(
            "error",
            "Unable to create inspection. Please ensure you are not in private browsing mode."
          );
          handleNavigateTo(null, "/inspections");
        });
    });
    setIsSaving(false);
  };

  const createFormData = (dto, files) => {
    let form = new FormData();
    var attachmentsToUpload = [];
    if (!_.isEmpty(files)) {
      for (var index = 0; index < files.length; index++) {
        let file = files[index];
        attachmentsToUpload.push({
          name: file.name,
          blob: file.slice(0, file.size, file.type),
        });
        var element = files[index];
        form.append("file", element);
      }
    }

    let dataString = JSON.stringify(dto);

    form.append("data", dataString);
    return form;
  };
  const handleSaveDraft = () => {
    if (!validateDraftDataForSave()) {
      let newInspectionState = validationService.unsetErrors(
        inspectionState,
        ...getErrorFieldArray(formFields)
      );
      setInspectionState(newInspectionState);
      return;
    }
    setIsSaving(true);
    let inspectionEventToSave = validationService.unsetErrors(
      formState,
      "assetIdError",
      "inspectionTypeIdError"
    );
    const errorFields = getErrorFieldArray(formFields);

    let inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    const dtoToSave = {
      ...inspectionEventToSave,
      assetId: inspectionEventToSave.assetId,
      keyValuePairs: JSON.stringify(inspectionFormValuesToSave),

      isSubmit: false,
    };
    const formToSave = createFormData(dtoToSave, uploadedFiles);
    if (!isExistingInspection()) {
      createInspection(formToSave, false)
        .then((res) => {
          showAlert("success", "Inspection saved.");
          setIsSaving(false);
          handleNavigateTo(null, `/inspections/${res.data.id}`);
        })
        .catch((err) => {
          handleCreateCatch(err, dtoToSave, uploadedFiles);
        });
    } else {
      updateInspection(props.match.params.inspectionId, dtoToSave, false)
        .then((res) => {
          showAlert("success", "Inspection saved.");
          setIsSaving(false);
        })
        .catch((err) => {
          setIsSaving(false);
          showAlert("error", err.response.data.message);
        });
    }
  };

  const validateSubmitDataForSave = () => {
    let newState = { ...inspectionState };
    let isFormValid = false;
    formFields
      .filter((x) => x.isRequired)
      .forEach((field) => {
        validationService.validateRequiredField(
          newState,
          field.name,
          `${field.name}Error`,
          field.label
        );
      });

    isFormValid = !validationService.hasError(
      newState,
      ...getErrorFieldArray(formFields)
    );

    let eventDataValid = validateDraftDataForSave(true);
    if (!isFormValid) {
      setInspectionState(newState);
    }
    if (!isFormValid || !eventDataValid) {
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid && eventDataValid;
  };

  const validateDraftDataForSave = (skipAlert = false) => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "assetId",
      "assetIdError",
      "Asset"
    );

    validationService.validateRequiredField(
      newState,
      "inspectionFormId",
      "inspecitonFormIdError",
      "Inspection Form"
    );
    validationService.validateRequiredField(
      newState,
      "inspectionDate",
      "inspectionDateError",
      "Inspection Date"
    );

    if (selectedInspectionForm.isEquipmentInspection) {
      validationService.validateRequiredField(
        newState,
        "equipmentId",
        "equipmentIdError",
        "Equipment Date"
      );
    }

    let errorFields = [
      "assetIdError",
      "inspecitonFormIdError",
      "inspectionDateError",
      "equipmentIdError",
    ];

    isFormValid = !validationService.hasError(newState, ...errorFields);
    if (!isFormValid) {
      setFormState(newState);
      if (!skipAlert) {
        showAlert("error", "Form is not valid for saving.");
      }
    }
    return isFormValid;
  };

  const handleEquipmentModalClose = (reload, newId) => {
    setShowAddEquipment(false);
    if (reload) {
      getEquipment({ pageSize: 1000 }).then((res) => {
        setEquipment(res.data);
        if (newId) {
          setFormState({ ...formState, equipmentId: newId });
        }
      });
    }
  };

  return (
    <Grid>
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="form-header-text"
                  component="div"
                >
                  {isExistingInspection()
                    ? formState.inspectionNumber
                    : "Add Inspection"}
                </Typography>
                {isExistingInspection() && (
                  <div className="align-self-center ml-5 mt-1">
                    {getStatusChip(formState.status)}
                  </div>
                )}
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/inspections")}
                >
                  {"Inspections"}
                </Link>
                <Typography color="text.primary">
                  {isExistingInspection()
                    ? formState.inspectionNumber
                    : "Add Inspection"}
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row>
          <Container
            className="mt-2"
            disableGutters={dimensions.width < 900}
            maxWidth="xl"
          >
            <Form className="card ">
              <Card.Body className="p-0">
                <Accordion
                  expanded={detailsExpanded}
                  onChange={() => setDetailsExpanded(!detailsExpanded)}
                  className="w-100"
                >
                  <AccordionSummary
                    aria-controls="details-content"
                    id="details-header"
                  >
                    <Typography>Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid.Row>
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Asset">
                          <Form.Select
                            name="assetId"
                            onChange={handleInputChange}
                            value={formState.assetId}
                            error={formState.assetIdError}
                          >
                            <option value={""}></option>
                            {assets.map((asset) => (
                              <option value={asset.id} key={asset.id}>
                                {asset.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label={"Inspection Form"}>
                          <Form.Select
                            name="inspectionFormId"
                            onChange={handleInspectionFormChange}
                            value={formState.inspectionFormId}
                            error={formState.inspectionFormIdError}
                            disabled={isExistingInspection()}
                          >
                            <option value={""}></option>
                            {inspectionForms
                              .filter(
                                (x) =>
                                  _.toLower(x.formType) ==
                                  _.toLower(InspectionFormTypes.Inspection)
                              )
                              .filter(
                                (f) =>
                                  !f.isRetired ||
                                  f.id == inspection.inspectionFormId
                              )
                              .map((form) => (
                                <option value={form.id} key={form.id}>
                                  {form.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <Form.Group label="Inspection Date" isRequired>
                          <Form.Input
                            type="date"
                            value={formState.inspectionDate}
                            name="inspectionDate"
                            error={formState.inspectionDateError}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      {/* TODO Implement Equipment */}
                      {selectedInspectionForm &&
                        selectedInspectionForm.isEquipmentInspection && (
                          <Grid.Col md={6} width={12}>
                            <Form.Group isRequired label={"Equipment"}>
                              <Form.Select
                                name="equipmentId"
                                onChange={handleInputChange}
                                value={formState.equipmentId}
                                error={formState.equipmentIdError}
                                disabled={!formState.assetId}
                              >
                                <option value={""}></option>
                                {equipment
                                  .filter((x) => x.assetId == formState.assetId)
                                  .map((e) => (
                                    <option value={e.id} key={e.id}>
                                      {e.name}
                                    </option>
                                  ))}
                              </Form.Select>
                              {formState.assetId && !isOffline && (
                                <Typography variant="caption">
                                  Equipment not listed?
                                  <Link
                                    href="#"
                                    className="ml-2"
                                    underline="none"
                                    onClick={() => {
                                      setShowAddEquipment(true);
                                    }}
                                  >
                                    + Add New
                                  </Link>
                                </Typography>
                              )}
                            </Form.Group>
                          </Grid.Col>
                        )}

                      <Grid.Col md={6} width={12}>
                        <Form.Group label="Assigned To">
                          <SearchableSelect
                            name="assignedToUserId"
                            options={users}
                            labelField="fullName"
                            idField="id"
                            value={formState.assignedToUserId}
                            placeholder={"Search or choose from list"}
                            onChange={(newValue) => {
                              setFormState((existing) => {
                                return {
                                  ...existing,
                                  assignedToUserId: newValue.id,
                                };
                              });
                            }}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="Description">
                          <LynxTextArea
                            value={formState.description}
                            name="description"
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </AccordionDetails>
                </Accordion>
                {!_.isEmpty(selectedInspectionForm) && (
                  <Accordion
                    expanded={inspectionExpanded}
                    onChange={() => setInspectionExpanded(!inspectionExpanded)}
                    className="w-100"
                  >
                    <AccordionSummary
                      aria-controls="form-content"
                      id="form-header"
                    >
                      <Typography>{"Inspection Form"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid.Row>
                        <Grid.Col width={12}>
                          <div className="mb-4">{getFormButtons()}</div>
                        </Grid.Col>
                        <Grid.Col width={12}>
                          <Divider className="mb-2" />
                        </Grid.Col>
                      </Grid.Row>

                      <div className="form-container" ref={formRef}>
                        <div className="w-100 d-print-flex ml-2 mb-2 d-none">
                          <div
                            style={{
                              border: "1px solid black",
                              padding: 10,
                              width: 300,
                            }}
                          >
                            <div>
                              Asset: {inspection.assetName}
                              <br></br>
                              Inspection Date: {inspection.inspectionDate}
                              <br></br>
                              Assigned To: {inspection.assignedToUserFullName}
                              <br></br>
                              Description:&nbsp;
                              {inspection.description}
                            </div>
                          </div>
                          <div
                            className="ml-2"
                            style={{
                              border: "1px solid black",
                              padding: 10,
                              width: 300,
                            }}
                          >
                            <div>
                              Created Date:&nbsp;
                              {dateUtil.convertDateTimeToLocal(
                                inspection.createdDateTimeUtc
                              )}
                              <br></br>
                              Created By:&nbsp;
                              {inspection.createdByUserFullName}
                              <br></br>
                              Submitted Date:&nbsp;
                              {dateUtil.convertDateTimeToLocal(
                                inspection.completedDateTime
                              )}
                              <br></br>
                              Submitted By:&nbsp;
                              {inspection.completedByUserFullName}
                            </div>
                          </div>
                        </div>

                        <Grid.Row>
                          <Grid.Col width={12}>
                            <Typography variant="h6" className="mb-2">
                              {selectedInspectionForm.title}
                            </Typography>
                          </Grid.Col>

                          {!_.isEmpty(inspectionState) &&
                            _.orderBy(
                              selectedInspectionForm.inspectionFormFields,
                              ["position"]
                            ).map((field) => {
                              return (
                                <InspectionFormField
                                  field={field}
                                  signatureValue={
                                    field.type == CustomFieldTypes.Signature
                                      ? inspectionState[
                                          field.name + "_signature"
                                        ]
                                      : null
                                  }
                                  value={inspectionState[field.name]}
                                  handleInputChange={
                                    handleInspectionInputChange
                                  }
                                  error={inspectionState[`${field.name}Error`]}
                                  key={field.name}
                                />
                              );
                            })}
                        </Grid.Row>
                      </div>

                      <Grid.Row>
                        <Grid.Col width={12}>
                          <Divider className="mb-3" />
                        </Grid.Col>
                        <Grid.Col width={12}>
                          <div className="mb-0">{getFormButtons()}</div>
                        </Grid.Col>
                      </Grid.Row>
                    </AccordionDetails>
                  </Accordion>
                )}
                <Accordion className="w-100">
                  <AccordionSummary
                    aria-controls="attachments-content"
                    id="attachments-header"
                  >
                    <Typography>Attachments</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AttachmentViewer
                      isExistingEntity={isExistingInspection()}
                      entityType={EntityTypes.InspectionEvent}
                      entityId={props.match.params.inspectionId}
                      cardClass="mb-0"
                      handleSetUploadedFiles={(files) => {
                        setUploadedFiles(files);
                      }}
                      rowHeight={200}
                      cols={6}
                    />
                  </AccordionDetails>
                </Accordion>
              </Card.Body>
            </Form>
          </Container>
        </Grid.Row>
        {isSaving && (
          <LynxDialog
            open={isSaving}
            title={`Saving ${"Inspection"}. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
        {showChangeWarning != null && (
          <LynxDialog
            open={showChangeWarning != null}
            title={`Are you sure you want to change the inspection form?`}
            description={"All inputted values will be lost."}
            handleConfirm={handleConfirmInspectionFormChange}
            handleClose={() => {
              setShowChangeWarning(null);
            }}
          />
        )}{" "}
        {showAddEquipment && (
          <LynxDialog
            maxWidth={"md"}
            open={showAddEquipment}
            dialogContent={
              <EquipmentModal
                handleModalClose={handleEquipmentModalClose}
                entity={{}}
                defaultAssetId={formState.assetId}
              />
            }
          />
        )}
      </Dimmer>
    </Grid>
  );
}
export const getStatusChip = (status) => {
  if (_.toLower(status) == "draft") {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (_.toLower(status) == "incomplete") {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (_.toLower(status) == "overdue") {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.error, color: "white" }}
      />
    );
  }
  return <Chip label={status} color="primary" />;
};
