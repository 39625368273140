import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import TimeAgo from "javascript-time-ago";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, FC } from "react";
import { Dimmer, Form, Grid } from "components/lynx-components";
import colors from "../../../modules/lynxColors";

import { UserRoles } from "types/enums";
import { roleMatch } from "../../../actions/auth";
import lynxColors from "../../../modules/lynxColors";
import { getReviewsForEntity } from "../../../services/entity-review";
import { BootstrapInput } from "../../bootstrap-inputs";
import { createEntityReview } from "./../../../services/entity-review";
import useAlert from "hooks/useAlert";
import { EntityReviewDto } from "types";

interface ReviewProps {
  entityType: string;
  entityId: number;
  isEventDetails?: boolean;
  hasPendingActions?: boolean;
  setEventLocked: (locked: boolean, save: boolean) => void;
}

const Review: FC<ReviewProps> = (props) => {
  const timeAgo = new TimeAgo("en-US");
  const [reviews, setReviews] = useState<EntityReviewDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newReview, setNewReview] = useState("");
  const [reviewCount, setReviewCount] = useState(5);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (props.entityType && props.entityId) {
      getReviewsForEntity(props.entityType, props.entityId).then((res) => {
        setReviews(res.data);
        setIsLoading(false);
      });
    }
  }, [props.entityType, props.entityId]);

  const getInitials = (fullName: string): string => {
    if (fullName) {
      return fullName
        .split(" ")
        .map((n) => n[0])
        .join("");
    }
    return "";
  };

  const isReviewLocked = (): boolean => {
    if (!_.isEmpty(reviews)) {
      return reviews[0].reviewCompleted as boolean;
    }
    return false;
  };

  const handleMarkAsReviewed = (isCompleted: boolean): void => {
    setIsLoading(true);
    const dto = {
      eventId: props.entityId,
      reviewCompleted: isCompleted,
      comments: newReview,
    };

    createEntityReview(dto)
      .then((res) => {
        setNewReview("");
        setReviews(res.data);
        setIsLoading(false);
        showAlert("success", "Review added.");
      })
      .catch((err) => {
        showAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dimmer active={isLoading} loader>
        <Grid.Row>
          {roleMatch([UserRoles.Admin]) && (
            <>
              <Grid.Col md={12} width={12}>
                <Form.Group label="Add Review" className="mb-1">
                  <BootstrapInput
                    className="mt-0"
                    id="standard-multiline-static"
                    multiline
                    fullWidth
                    rows={props.isEventDetails ? 2 : 4}
                    value={newReview}
                    onChange={(event) => {
                      setNewReview(event.target.value);
                    }}
                  />
                </Form.Group>
              </Grid.Col>
              {!isReviewLocked() && (
                <Grid.Col md={12} width={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-2"
                    onClick={() => {
                      handleMarkAsReviewed(true);
                      props.setEventLocked(true, true);
                    }}
                    disabled={props.hasPendingActions}
                    startIcon={<LockIcon />}
                  >
                    Complete
                  </Button>
                  <Button
                    variant="outlined"
                    className="floating-error-button"
                    color="error"
                    onClick={() => handleMarkAsReviewed(false)}
                  >
                    Incomplete
                  </Button>
                </Grid.Col>
              )}
              {isReviewLocked() && (
                <Grid.Col md={12} width={12}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      handleMarkAsReviewed(false);
                      props.setEventLocked(false, true);
                    }}
                    startIcon={<LockOpenIcon />}
                  >
                    Unlock event - incomplete
                  </Button>
                </Grid.Col>
              )}
            </>
          )}
          {!_.isEmpty(reviews) && (
            <Grid.Col md={12} width={12} className="mt-2">
              <Form.Group label="Past Reviews">
                <List className="pt-0">
                  <Divider />
                  {reviews.slice(0, reviewCount).map((review, index) => (
                    <React.Fragment key={index}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            src="/broken-image.jpg"
                            sx={{ bgcolor: colors.harvestOrange }}
                          >
                            {getInitials(review.reviewByUserFullName as string)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            !review.reviewCompleted ? (
                              <>
                                <div className="d-flex">
                                  <ClearIcon sx={{ color: "red" }} />
                                  <span className="pb-2 ml-1">Incomplete</span>
                                </div>
                                <div>{review.comments}</div>
                              </>
                            ) : (
                              <>
                                <div className="d-flex">
                                  <CheckIcon
                                    sx={{ color: lynxColors.success }}
                                  />
                                  <span className="pb-2 ml-1">Complete</span>
                                </div>
                                <div>{review.comments}</div>
                              </>
                            )
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {timeAgo.format(
                                  _.toNumber(
                                    moment
                                      .utc(review.reviewDateTimeUtc)
                                      .valueOf()
                                  )
                                )}
                                &nbsp;-&nbsp;{review.reviewByUserFullName}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      {roleMatch([UserRoles.Admin]) && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
                {reviewCount < reviews.length && (
                  <Button
                    onClick={() => {
                      setReviewCount(reviewCount + 5);
                    }}
                  >
                    Show More
                  </Button>
                )}
              </Form.Group>
            </Grid.Col>
          )}
          {_.isEmpty(reviews) && !roleMatch([UserRoles.Admin]) && (
            <Grid.Col md={12} width={12}>
              No Reviews
            </Grid.Col>
          )}
          {props.hasPendingActions && (
            <Grid.Col md={12} width={12} className="mt-2 mb-0">
              <span className="invalid-feedback" style={{ display: "inline" }}>
                Pending actions must be closed before the event can be
                completed.
              </span>
            </Grid.Col>
          )}
        </Grid.Row>
      </Dimmer>
    </>
  );
};

export default Review;
