import axios from "axios";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import account from "reducers/account";
import auth from "reducers/auth";
import organization from "reducers/organization";
import portal from "reducers/portal";
import axiosMiddleware from "redux-axios-middleware";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiService, mainUrl } from "services/rtkApi/apiService";
import lookupReducer from "../reducers/lookups";
import { LocalStorageKeys } from "../types/enums";
import { combineReducers } from "redux";
import { LookupState, OrganizationDto, UserDto } from "types";

//update this to be devUrl, betaUrl, prodUrl or demoUrl depending on backend environment

export const apiUrl = mainUrl + "api";

export const client = axios.create({
  baseURL: apiUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem(LocalStorageKeys.AccessToken);
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

const persistConfig = {
  key: "lookups",
  storage,
};

const lookupPersistedReducer = persistReducer(persistConfig, lookupReducer);
const rootReducer = combineReducers({
  auth,
  account,
  organization,
  portal,
  lookups: lookupPersistedReducer,
  [apiService.reducerPath]: apiService.reducer,
});
export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false })
      .concat(thunk, axiosMiddleware(client))
      .concat(apiService.middleware);
  },
  devTools: process.env.NODE_ENV !== "production",
});
export type AppStore = typeof appStore;
export const persistor = persistStore(appStore);
//export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof appStore.dispatch;
