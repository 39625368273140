import React, { useState } from "react";
import { Page, Grid, Form, Card, Dimmer } from "components/lynx-components";
import { getUserForRegister, registerUser } from "../../services/login";
import { LynxDialog } from "./../lynx-dialog";
import { logoutUser } from "../../actions/auth";
import { useDispatch } from "react-redux";
import { getLookup } from "./../../services/lookup";
import { Link } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "./../../services/validation";
import { getOrgLogo } from "services/organization-service";
import { LocalStorageKeys } from "types/enums";
import useAlert from "hooks/useAlert";
var passwordValidator = require("password-validator");
var schema = new passwordValidator();
schema
  .is()
  .min(6) // Minimum length 8
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits()
  .has()
  .symbols() // Must have at least 2 digits
  .has()
  .not()
  .spaces(); // Should not have spaces
export function RegisterAccount(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [user, setUser] = useState({});
  const [formState, setFormState] = useState({});
  const dispatch = useDispatch();
  const [timezones, setTimezones] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [orgLogo, setOrgLogo] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isLogoLoading, setIsLogoLoading] = useState(true);
  const { showAlert } = useAlert();
  React.useEffect(() => {
    //Clear login token
    dispatch(logoutUser());

    getUserForRegister(props.match.params.guid)
      .then((res) => {
        setUser(res.data);
        if (!res.data.isPortalUser) {
          getLookup("timezone").then((res) => {
            setTimezones(res.data);
            setIsDataLoading(false);
          });
        } else {
          setIsDataLoading(false);
        }
        setFormStateFromUser(res.data);
      })
      .catch((err) => {
        setShowDialog(true);
        setIsDataLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(user) && user.isPortalUser) {
      getOrgLogo(user.organizationId).then((res) => {
        setOrgLogo(URL.createObjectURL(res.data));
        setIsLogoLoading(false);
      });
      return () => {
        URL.revokeObjectURL(orgLogo);
      };
    }
  }, [user]);

  const handleBack = () => {
    if (user.isPortalUser) {
      window.location.replace(user.organizationPortalUrl);
    } else {
      props.history.push("/");
    }
  };

  const setFormStateFromUser = (user) => {
    setFormState({
      organizationName: user.organizationName,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      city: user.city,
      postalCode: user.postalCode,
      timezoneId: user.timezoneId,
      coordinateType: user.coordinateType,
      newPassword: "",
      newPasswordConfirm: "",
      passwordErrorList: [],
    });
  };
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;
    _.set(newState, name, value);
    if (name == "newPassword") {
      _.set(
        newState,
        "passwordErrorList",
        schema.validate(value, { details: true })
      );
    }
    setFormState(newState);
  };
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "firstName",
      "firstNameError",
      "First Name"
    );
    validationService.validateRequiredField(
      newState,
      "lastName",
      "lastNameError",
      "Last Name"
    );

    validationService.validateRequiredField(
      newState,
      "timezoneId",
      "timezoneIdError",
      "Timezone"
    );

    isFormValid = !validationService.hasError(
      newState,
      "firstNameError",
      "lastNameError",
      "timezoneIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleRegister = () => {
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "firstNameError",
      "lastNameError",
      "timezoneIdError"
    );

    if (formToSave.newPassword === "") {
      showAlert("error", "Please enter New Password.");
      return;
    }

    if (formToSave.newPassword !== formToSave.newPasswordConfirm) {
      showAlert("error", "Passwords do not match.");
      return;
    }
    formToSave.isPortalUser = user.isPortalUser;
    registerUser(formToSave, props.match.params.guid)
      .then((res) => {
        setShowSuccessDialog(true);
        if (user.isPortalUser) {
          localStorage.setItem(LocalStorageKeys.AccessToken, res.data.token);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="content-wrapper">
      <Page>
        <Page.Main>
          <Page.Content>
            <Dimmer
              active={
                isDataLoading || isLogoLoading || (user && !user.isPortalUser)
              }
              loader
            >
              <Page.Title className="d-flex align-items-center">
                {user.isPortalUser && orgLogo && (
                  <img
                    src={orgLogo}
                    loading="lazy"
                    width="200"
                    alt="organization-logo"
                    className="mb-2 mr-5"
                  />
                )}

                {user.isPortalUser
                  ? `Register your Account for the ${user.organizationPortalName}, powered by Lynx`
                  : "Register your Account for the Lynx Platform"}
              </Page.Title>

              <Form className="card">
                <Card.Header>
                  <Card.Title>Account Details</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    {!user.isPortalUser && (
                      <Grid.Col md={6} width={12}>
                        <Form.Group label="Organization" isRequired>
                          <Form.Input
                            type="text"
                            disabled
                            placeholder="Company"
                            value={formState.organizationName}
                            name="organizationName"
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                    )}

                    <Grid.Col width={12} md={user.isPortalUser ? 12 : 6}>
                      <Form.Group label="Email address" isRequired>
                        <Form.Input
                          type="email"
                          placeholder="Email"
                          disabled
                          value={formState.email}
                          name="email"
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={12} md={6}>
                      <Form.Group label="First Name" isRequired>
                        <Form.Input
                          type="text"
                          placeholder="First Name"
                          value={formState.firstName}
                          error={formState.firstNameError}
                          name="firstName"
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col width={12} md={6}>
                      <Form.Group label="Last Name" isRequired>
                        <Form.Input
                          type="text"
                          placeholder="Last Name"
                          value={formState.lastName}
                          error={formState.lastNameError}
                          name="lastName"
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                    {!user.isPortalUser && (
                      <>
                        <Grid.Col width={12} md={6}>
                          <Form.Group>
                            <Form.Input
                              type="text"
                              label="City"
                              placeholder="City"
                              value={formState.city}
                              name="city"
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={12} md={6}>
                          <Form.Group>
                            <Form.Input
                              type="number"
                              label="Postal Code"
                              placeholder="ZIP Code"
                              value={formState.postalCode}
                              name="postalCode"
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={12} md={6}>
                          <Form.Group label="Timezone" isRequired>
                            <Form.Select
                              value={formState.timezoneId}
                              name="timezoneId"
                              onChange={handleInputChange}
                              error={formState.timezoneIdError}
                            >
                              {timezones.map((zone) => (
                                <option value={zone.id}>
                                  {zone.displayName}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col width={12} md={6}>
                          <Form.Group label="Coordinate Type" isRequired>
                            <Form.Select
                              value={formState.coordinateType}
                              name="coordinateType"
                              onChange={handleInputChange}
                            >
                              <option value="LatLong">
                                Latitude / Longitude
                              </option>
                              <option value="Utm">UTM</option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                      </>
                    )}
                  </Grid.Row>
                </Card.Body>
              </Form>
              <Form className="card">
                <Card.Header>
                  <Card.Title>Password</Card.Title>
                </Card.Header>
                <Card.Body>
                  {" "}
                  <Grid.Row>
                    <Grid.Col md={6} width={12}>
                      <Form.Group>
                        <Form.Label>Enter a Password</Form.Label>
                        <Form.Input
                          type="password"
                          value={formState.newPassword}
                          name="newPassword"
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                    {!_.isEmpty(formState.passwordErrorList) && (
                      <Grid.Col md={12} width={12}>
                        <Form.Group>
                          {formState.passwordErrorList.map((err) => (
                            <li className="text-danger" key={err.message}>
                              {err.message}
                            </li>
                          ))}
                        </Form.Group>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={6} width={12}>
                      <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Input
                          type="password"
                          value={formState.newPasswordConfirm}
                          name="newPasswordConfirm"
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                    {!_.isEmpty(formState.newPasswordConfirm) &&
                      formState.newPassword != formState.newPasswordConfirm && (
                        <Grid.Col md={12} width={12}>
                          <Form.Group>
                            <li className="text-danger">
                              Password does not match
                            </li>
                          </Form.Group>
                        </Grid.Col>
                      )}
                  </Grid.Row>
                </Card.Body>
              </Form>

              <Card>
                <Card.Body className="pb-0">
                  <Grid.Row>
                    <Grid.Col width={12} md={12}>
                      <Form.Group
                        label={
                          <div>
                            We and selected third parties collect personal
                            information as specified in the privacy policy and
                            use cookies or similar technologies for technical
                            purposes and, with your consent, for other purposes
                            as specified in the privacy policy. Denying consent
                            will make the software unusable. By selecting this
                            box, I am providing consent to the use of cookies
                            and that I have read and understand the&nbsp;
                            <Link
                              to="/privacy"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              privacy policy.
                            </Link>
                          </div>
                        }
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="acceptTerms"
                              onClick={(e) => {
                                setIsChecked(e.target.checked);
                              }}
                            />
                          }
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
                <Card.Footer>
                  <Button
                    variant="contained"
                    className="float-right"
                    disabled={
                      isChecked == false ||
                      formState.newPassword == "" ||
                      formState.newPasswordConfirm == "" ||
                      !_.isEmpty(formState.passwordErrorList) ||
                      formState.newPassword != formState.newPasswordConfirm
                    }
                    onClick={handleRegister}
                  >
                    Register
                  </Button>
                </Card.Footer>
              </Card>
            </Dimmer>
          </Page.Content>
        </Page.Main>
      </Page>
      <LynxDialog
        open={showDialog}
        handleConfirm={handleBack}
        title={`Registration Error`}
        description={`This registration link is no longer valid.`}
      />
      <LynxDialog
        open={showSuccessDialog}
        handleConfirm={handleBack}
        title={`Registration Successful`}
        description={
          user.isPortalUser
            ? `Your registration has been accepted and you will now be navigated to the ${user.organizationPortalName}.`
            : `Your registration has been accepted and you will now be navigated to the login screen.`
        }
      />
    </div>
  );
}
