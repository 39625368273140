import * as React from "react";

interface Props {
  children?: React.ReactNode;
}

const PageOptions: React.FC<Props> = ({ children }) => {
  return <div className="page-options d-flex">{children}</div>;
};

export default PageOptions;
