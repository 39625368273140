import React from "react";
import cn from "classnames";

// Define Props using TypeScript interface
interface Props extends React.HTMLProps<HTMLTableSectionElement> {
  children?: React.ReactNode;
  className?: string;
}

const TableBody: React.FC<Props> = ({ className, children, ...props }) => {
  const classes = cn(className);
  return (
    <tbody className={classes} {...props}>
      {children}
    </tbody>
  );
};

export default TableBody;
