import React, { useState } from "react";
import { Grid, Card, Icon } from "components/lynx-components";
import { getLookups } from "../../../../services/lookup";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { categoryColumns } from "./CategoryColumns";
import { EventSubCategoryColumns } from "./EventSubCategoryColumns";

import { AddLookupHeader } from "../add-lookup-header";
import { EventSubCategoryModel } from "./EventSubCategoryModel";

import { DeleteConfirmModal } from "../DeleteConfirmModal";
import { SettingsLookUpEditModal } from "../SettingsLookUpEditModal";

import { CustomFieldModal } from "./custom-field-modal";
import Button from "@mui/material/Button";
import {
  getCustomFields,
  updateCustomFieldSort,
} from "../../../../services/custom-fields-service";
import { customFieldColumns } from "./custom-field-columns";
import { roleMatch } from "../../../../actions/auth";
import { useHistory } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { EntityTypes, UserRoles, LookupTypes } from "../../../../types/enums";
import EventCorrespondenceLogType from "./EventCorrespondenceLogType";
const initialLookupData1 = {
  id: "",
  lookupType: "",
  code: "",
};

const EventCorrespondenceColumns = [
  {
    field: "code",
    headerName: "Type",
    width: 150,
    type: "string",
  },

  {
    field: "description",
    headerName: "Description",
    width: 250,
    type: "string",
  },

  {
    field: "delete",
    headerName: "",
    width: 45,
    type: "",

    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        {((roleMatch([UserRoles.Admin]) && !params.row.isInUse) ||
          roleMatch([UserRoles.InternalUser])) && (
          <Icon className="display-5 mb-3" name="trash" />
        )}
      </div>
    ),
  },

  {
    field: "edit",
    headerName: "",
    width: 45,
    type: "",
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        <Icon className="display-5 mb-3" name="edit" />
      </div>
    ),
  },
];

export function EventSettings(props) {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [EventSubCategoryData, setEventSubCategoryData] = useState([]);
  const [EventCorrespondenceLogTypeData, setEventCorrespondenceLogTypeData] = useState([]);

  const [selectedDataId, setSelectedDataId] = useState(0);
  const [eventCustomFields, setEventCustomFields] = useState([]);
  const [selectedCustomField, setSelectedCustomField] = useState({});
  const [selectedLookupData, setSelectedLookupData] =
    useState(initialLookupData1);
  const [initialLookupData, setInitialLookupData] = useState({});
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);

  const history = useHistory();
  const setLookupDataFromProps = (LookupItem) => {
    let newState = {
      id: LookupItem.id,
      lookupType: LookupItem.lookupType,
      code: LookupItem.code,
    };
    setSelectedLookupData(newState);
  };

  const getCategoryData = () => {
    getLookups("EventCategory", true).then((res) => {
      setCategoryData(res.data);
    });
  };

  const getEventSubCategoryData = () => {
    getLookups("EventSubCategory", true).then((res) => {
      setEventSubCategoryData(res.data);
    });
  };

  const getEventCorrespondenceLogType = () => {
    getLookups("CorrespondenceLogType", true).then((res) => {
      setEventCorrespondenceLogTypeData(res.data);
    });
  };

  React.useEffect(() => {
    getCategoryData();
    getEventSubCategoryData();
    getEventCorrespondenceLogType();
    loadCustomFields();
  }, []);

  const loadCustomFields = () => {
    getCustomFields(EntityTypes.Event).then((res) => {
      setEventCustomFields(res.data);
    });
  };

  const handleRefreshEventSubCategory = (refreshData) => {
    if (refreshData) {
      getEventSubCategoryData();
    }
  };

  const handleRefreshCategory = (refreshData) => {
    if (refreshData) {
      getCategoryData();
    }
  };

  const handleRowSelected = (e) => {
    setSelectedDataId(e.id);
  };

  const handleEditDelete = (e) => {
    if (
      (e.field == "delete" && roleMatch([UserRoles.Admin]) && !e.row.isInUse) ||
      roleMatch([UserRoles.InternalUser])
    ) {
      setSelectedDataId(e.row.id);
      setLookupDataFromProps(e.row);

      setDeleteOpen(true);
    }

    if (e.field == "edit") {
      setSelectedDataId(e.row.id);
      setLookupDataFromProps(e.row);

      setEditOpen(true);
    }
  };

  const handleCustomFieldEdit = (e) => {
    if (e.field == "edit") {
      setSelectedCustomField(eventCustomFields.find((x) => x.id == e.row.id));
      setShowCustomFieldModal(true);
    }
  };

  const handleClose = (refreshData) => {
    setDeleteOpen(false);
    setEditOpen(false);
    setSelectedDataId(0);
    if (refreshData) {
      switch (selectedLookupData.lookupType) {
        case "EventCategory":
          getCategoryData();
          getEventSubCategoryData();
          break;
        case "EventSubCategory":
          getEventSubCategoryData();
          break;
        case "CorrespondenceLogType":
          getEventCorrespondenceLogType();
          break;
      }
    }
    setSelectedLookupData(initialLookupData);
  };

  const handleRowOrderChange = (e) => {
    updateCustomFieldSort({
      entityId: e.row.id,
      newPosition: e.targetIndex + 1,
      entityType: EntityTypes.Event,
    });
    let newValues = [...eventCustomFields];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    setEventCustomFields(newValues);
  };

  const handleCustomFieldModalClosed = (refresh) => {
    setShowCustomFieldModal(false);
    if (refresh == true) {
      getCategoryData();
      getEventSubCategoryData();
    }
    loadCustomFields();
    setSelectedCustomField({});
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Event Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Event Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>

      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Event Additional Fields
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Additional fields are used to customize the fields used to enter
              data for an Event Category on the Add Event form. You can create
              and assign additional fields to Event Categories here.
            </Typography>
          </div>
        </Grid.Col>

        <Grid.Col md={10} width={12}>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => setShowCustomFieldModal(true)}
              >
                Add
              </Button>
            </Card.Header>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                columns={customFieldColumns}
                rows={eventCustomFields}
                onRowOrderChange={handleRowOrderChange}
                onCellClick={handleCustomFieldEdit}
                rowReordering
                disableColumnReorder
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                disableChildrenSorting
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Event Categories
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              An Event Category is a broad-based category for data entry on the
              Add Event form (e.g., water stewardship, community engagement).
            </Typography>
          </div>
        </Grid.Col>

        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Category"
              descriptionLabel="Description"
              lookupType={LookupTypes.EventCategory}
              lookupName="Event Category"
              handleAddLookup={() => handleRefreshCategory(true)}
            ></AddLookupHeader>
            <div style={{ height: 630, width: "100%" }}>
              {" "}
              <DataGridPro
                rows={CategoryData}
                columns={categoryColumns}
                disableMultipleSelection={true}
                onCellClick={handleEditDelete}
                onRowClick={handleRowSelected}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Event Subcategories
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              An Event Subcategory is more specific than an Event Category and
              is attached to specific event categories. (e.g., a water
              withdrawal subcategory under a water stewardship category).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <EventSubCategoryModel
              handleAddEventSubCategory={handleRefreshEventSubCategory}
              categories={CategoryData}
            ></EventSubCategoryModel>

            <div style={{ height: 630, width: "100%" }}>
              {" "}
              <DataGridPro
                rows={EventSubCategoryData}
                columns={EventSubCategoryColumns}
                disableMultipleSelection={true}
                onCellClick={handleEditDelete}
                onRowClick={handleRowSelected}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Event Correspondence Log Type
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              (e.g., Phone Call, Email, Written Correspondence, Direct Message on Social or Other Platforms).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <EventCorrespondenceLogType
              handleSaveSuccess={() => getEventCorrespondenceLogType()}
            ></EventCorrespondenceLogType>

            <div style={{ height: 630, width: "100%" }}>
              {" "}
              <DataGridPro
                rows={EventCorrespondenceLogTypeData}
                columns={EventCorrespondenceColumns}
                disableMultipleSelection={true}
                onCellClick={handleEditDelete}
                onRowClick={handleRowSelected}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteOpen}
        onClose={handleClose}
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DeleteConfirmModal
          handleModalClose={handleClose}
          lookupId={selectedDataId}
          lookupItem={selectedLookupData}
        ></DeleteConfirmModal>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editOpen}
        onClose={handleClose}
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <SettingsLookUpEditModal
          handleModalClose={handleClose}
          lookupId={selectedDataId}
          lookupItem={selectedLookupData}
        ></SettingsLookUpEditModal>
      </Modal>
      {showCustomFieldModal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showCustomFieldModal}
          onClose={() => {
            setShowCustomFieldModal(false);
            setSelectedCustomField({});
          }}
          disableBackdropClick
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <CustomFieldModal
            handleModalClose={handleCustomFieldModalClosed}
            customField={selectedCustomField}
            entityType={EntityTypes.Event}
          ></CustomFieldModal>
        </Modal>
      )}
    </Grid>
  );
}
