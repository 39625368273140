import React, { useState } from "react";

import Button from "@mui/material/Button";
import _ from "lodash";
import { validationService } from "../../../../services";
import { Grid, Card, Form } from "components/lynx-components";
import { createParameter } from "../../../../services/parameters";
import useAlert from "hooks/useAlert";

const initialForm = {
  name: "",
  description: "",
  defaultUnitId: "",
};

export function AddParameterHeader(props) {
  const [formState, setFormState] = useState(initialForm);
  const { showAlert } = useAlert();
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(formState, "nameError");
    if (props.id > 0) {
    } else {
      formToSave.id = 0;
      createParameter(formToSave)
        .then((res) => {
          showAlert("success", `Parameter created.`);
          props.handleAddParameter();
          setFormState(initialForm);
        })
        .catch((err) => {
          showAlert("error", err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    isFormValid = !validationService.hasError(newState, "nameError");

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Form className="w-30 h-auto">
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={5} width={12}>
            <Form.Group label={"Name"}>
              <Form.Input
                type="text"
                value={formState.name}
                name="name"
                onChange={handleInputChange}
                error={formState.nameError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={5} width={12}>
            <Form.Group label="Default Unit">
              <Form.Select
                name="defaultUnitId"
                onChange={handleInputChange}
                value={formState.defaultUnitId}
              >
                <option value={""}></option>
                {props.units.map((unit) => (
                  <option value={unit.id} key={unit.id}>
                    {unit.code}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Form.Group label={"Description"}>
              <Form.Input
                type="text"
                value={formState.description}
                name="description"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={2} width={12}>
            <Form.Group label="&nbsp;&nbsp;">
              <Button
                variant="contained"
                className="float-right"
                onClick={saveForm}
              >
                Add
              </Button>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Form>
  );
}
