import * as React from "react";
import cn from "classnames";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const DimmerContent: React.FC<Props> = ({ className, children }) => {
  const classes = cn({ "dimmer-content": true }, className);
  return <div className={classes}>{children}</div>;
};

export default DimmerContent;
