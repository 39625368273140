import Button from "@mui/material/Button";
import React, { useState } from "react";
import { Card, Form, Grid } from "components/lynx-components";
import { SearchableSelect } from "./../../form-controls/searchable-select";
import { IconButton, Stack, Typography } from "@mui/material";
import { dateUtil } from "../../../services/date-util";
import SingleSelect from "../../form-controls/single-select";
import { LynxDialog } from "components/lynx-dialog";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { IncidentStatus, UserRoles } from "types/enums";
import { roleMatch } from "actions/auth";
import { IncidentDto, LookupDto, UserDto } from "types";

interface IncidentInitialReviewSectionProps {
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formState: {
    typeId: string;
    typeIdError?: string;
    subTypeId: string;
    subTypeIdError?: string;
    severityId: string;
    severityIdError?: string;
    departmentId: string;
    investigationRequired: boolean;
    investigationLeadUserId: string;
    assetId?: string;
  };
  incidentTypes: Array<LookupDto>;
  selectedSubTypes: Array<LookupDto>;
  incidentServerities: Array<LookupDto>;
  users: Array<UserDto>;
  incidentStatus: string;
  saveIncident: (action: string) => void;
  formDisabled?: boolean;
  handleInvestigatorChange: (value: string) => void;
  incident: IncidentDto;
  departments: Array<{ id: string; name: string }>;
  isLocked: boolean;
  toggleLock: (section: string) => void;
  readonly?: boolean;
}

const IncidentInitialReviewSection: React.FC<IncidentInitialReviewSectionProps> = (props) => {
  const {
    handleInputChange,
    formState,
    incidentTypes,
    selectedSubTypes,
    incidentServerities,
    users,
    incidentStatus,
    saveIncident,
    formDisabled,
    handleInvestigatorChange,
    incident,
    departments,
    isLocked,
    toggleLock,
    readonly,
  } = props;

  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  
  const getLookupLabels = (value: LookupDto) => {
    return (
      <div>
        {value.code}
        {value.description && (
          <Typography variant="caption" component="span">
            &nbsp;-&nbsp;<i>{value.description}</i>
          </Typography>
        )}
      </div>
    );
  };

  return (
    <Form className="card mb-0 d-print-none">
      <Card.Header>
        <Card.Title>
          <Stack direction="row" alignItems="center">
            <span>Initial Review</span>
            {roleMatch([UserRoles.Admin]) && incidentStatus !== IncidentStatus.PendingReview && (
              <IconButton
                aria-label="Lock"
                onClick={() => toggleLock("initialReview")}
                title={isLocked ? "Unlock" : "Lock"}
              >
                {isLocked ? <Lock /> : <LockOpenOutlined color="success" />}
              </IconButton>
            )}
          </Stack>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Actual Type">
              <SingleSelect
                name="typeId"
                onChange={handleInputChange}
                value={formState.typeId}
                error={formState.typeIdError}
                disabled={isLocked || readonly}
                dropdownValues={incidentTypes}
                labelFunction={getLookupLabels}
                id="id"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Actual Subtype">
              <SingleSelect
                name="subTypeId"
                onChange={handleInputChange}
                value={formState.subTypeId}
                error={formState.subTypeIdError}
                disabled={isLocked || formState.typeId === "" || readonly}
                dropdownValues={selectedSubTypes}
                labelFunction={getLookupLabels}
                id="id"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group isRequired label="Actual Severity">
              <SingleSelect
                name="severityId"
                onChange={handleInputChange}
                value={formState.severityId}
                error={formState.severityIdError}
                disabled={isLocked || readonly}
                dropdownValues={incidentServerities}
                labelFunction={getLookupLabels}
                id="id"
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={6} width={12}>
            <Form.Group label="Actual Department">
              <SingleSelect
                onChange={handleInputChange}
                dropdownValues={departments}
                label="name"
                id="id"
                name="departmentId"
                value={formState.departmentId}
                disabled={isLocked || readonly}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group>
              <Form.Checkbox
                label="Is further investigation required?"
                name="investigationRequired"
                checked={formState.investigationRequired}
                onChange={handleInputChange}
                disabled={isLocked || readonly}
              />
            </Form.Group>
          </Grid.Col>
          {formState.investigationRequired && (
            <Grid.Col md={6} width={12}>
              <Form.Group label="Investigation Lead">
                <SearchableSelect
                  name="investigationLeadUserId"
                  options={users}
                  labelField="fullName"
                  disabled={
                    ((formDisabled || formState.typeId === "" || formState.assetId === "") && isLocked) ||
                    readonly
                  }
                  idField="id"
                  value={formState.investigationLeadUserId}
                  placeholder="Search or choose from list"
                  onChange={handleInvestigatorChange}
                />
              </Form.Group>
            </Grid.Col>
          )}
        </Grid.Row>
      </Card.Body>
      {incidentStatus === IncidentStatus.PendingReview && !readonly && (
        <Card.Footer>
          <Button
            className="float-right"
            variant="contained"
            color="success"
            onClick={() => saveIncident("initialreview")}
          >
            Save and Submit
          </Button>
        </Card.Footer>
      )}
      {incidentStatus !== IncidentStatus.PendingReview && !readonly && (
        <Card.Footer>
          <Button
            className="float-right"
            variant="contained"
            color="success"
            disabled={isLocked}
            onClick={() => {
              if (formState.investigationLeadUserId !== incident.investigationLeadUserId?.toString()) {
                setIsWarningOpen(true);
              } else {
                saveIncident("saveinitialreview");
              }
            }}
          >
            Save
          </Button>
          <div>
            <Typography component="span">
              Initial review submitted by: {incident.initialReviewCompletedByUserFullName}{" "}
              {dateUtil.convertDateTimeToLocal(incident.initialReviewCompletedDateTimeUtc)}
            </Typography>
          </div>
        </Card.Footer>
      )}
      <LynxDialog
        title="Warning"
        description="Are you sure you want to change the investigation team? The users may lose access to the investigation."
        open={isWarningOpen}
        handleClose={() => setIsWarningOpen(false)}
        buttons={() => (
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              saveIncident("saveinitialreview");
              setIsWarningOpen(false);
            }}
          >
            Yes
          </Button>
        )}
      />
    </Form>
  );
};

export default IncidentInitialReviewSection;