import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, Form } from "components/lynx-components";
import Chip from "@mui/material/Chip";

export function MonitoringLocationUploadStep1(props) {
  return props.monitoringLocations ? (
    <>
      <div style={{ width: "30%" }}>
        <Form.Group label="Monitoring Location">
          <Form.Select
            name="monitoringLocation"
            value={props.selectedMonitoringLocationId}
            onChange={(e) =>
              props.handleMonitoringLocationChange(e.target.value)
            }
          >
            <option value={""}></option>
            {props.monitoringLocations.map((ml) => (
              <option value={ml.id} key={ml.id}>
                {ml.monitoringLocationNumber} - {ml.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <Button variant="outlined" sx={{ width: 100 }} component="label">
        Upload
        <input
          hidden
          accept="image/*"
          type="file"
          multiple
          onChange={props.handleFileUpload}
        />
      </Button>
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={props.handleAnalyze}
            sx={{ mt: 1, mr: 1 }}
            disabled={
              props.selectedMonitoringLocationId <= 0 || props.files.length <= 0
            }
          >
            Upload for Analysis
          </Button>
        </div>
      </Box>
      <Grid>
        <Grid.Row>
          {props.files ? (
            props.files.map((file, i) => (
              <>
                <div class="mt-2">
                  <Chip
                    label={file.name}
                    variant="outlined"
                    clickable
                    component="a"
                    onDelete={() => props.handleFileDelete(i)}
                  />
                </div>
              </>
            ))
          ) : (
            <></>
          )}
        </Grid.Row>
      </Grid>
    </>
  ) : (
    <></>
  );
}
