import React, { useState, useEffect } from "react";
import { Icon, Card, Form, Dimmer } from "components/lynx-components";
import { getLookup, deleteLookup } from "../../../services/lookup";
import Button from "@mui/material/Button";
import useAlert from "hooks/useAlert";

const initialForm = {
  id: "",
  lookupType: "",
  code: "",
};

export function DeleteConfirmModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [initialFormState, setInitialFormState] = useState(initialForm);
  const [formTitle, SetFormTitle] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { showAlert } = useAlert();
  // const handleInputChange = (e) => {
  //   let newState = { ...formState };
  //   const { name, value } = e.target;

  //   _.set(newState, name, value);

  //   setFormState(newState);
  // };

  useEffect(() => {
    if (props.lookupId > 0) {
      // setFormStateFromProps(props.lookupItem  );
      //   setIsLoading(false);
      getLookup(props.lookupId).then((res) => {
        setFormStateFromProps(res.data);
        setIsLoading(false);
      });
    } else {
      setFormStateFromProps(initialFormState);
      setIsLoading(false);
    }
  }, [props.lookupId]);

  const setFormStateFromProps = (LookupItem) => {
    let newState = {
      id: LookupItem.id,
      lookupType: LookupItem.lookupType,
      code: LookupItem.code,
    };
    setFormState(newState);

    switch (LookupItem.lookupType) {
      case "IncidentCategory":
        SetFormTitle("Category");
        break;
      case "IncidentLocationType":
        SetFormTitle("Location Type");
        break;
      case "IncidentType":
        SetFormTitle("Type");
        break;
      case "CustomerType":
        SetFormTitle("Type");
        break;
      case "CustomerCategory":
        SetFormTitle("Category");
        break;
    }

    // setInitialFormState(newState);
  };

  const deleteItem = () => {
    deleteLookup(props.lookupId).then(() => {
      showAlert("success", formState.code + " deleted.");
      props.handleModalClose(true);
    });
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Dimmer active={isLoading} loader>
          <Card.Title>
            <Icon
              name="x"
              onClick={props.handleModalClose}
              className="float-right pointer"
            ></Icon>
            Delete {formTitle}
          </Card.Title>
          <div align="center">
            Do you want to delete this item? <br></br>
            <b>{formState.code}</b>
          </div>
        </Dimmer>
      </Card.Body>

      <Card.Footer>
        <Button
          color="error"
          className="float-right"
          variant="contained"
          onClick={deleteItem}
        >
          Delete
        </Button>
      </Card.Footer>
    </Form>
  );
}
