import LockIcon from "@mui/icons-material/Lock";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { roleMatch } from "actions/auth";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Layer,
  Map,
  MapProps,
  MapRef,
  Marker,
  NavigationControl,
  Source,
} from "react-map-gl";
import { EventDto } from "types";
import { UserRoles } from "types/enums";
import lynxColors from "../../../modules/lynxColors";
import { getAssetLayerById } from "../../../services/assets";
import { MAPBOX_TOKEN } from "../map/dashboard-map";
import Pin from "../map/pin";

interface EventDetailsMapProps {
  event: EventDto;
  eventLocked: boolean;
  handleEdit: (field: string) => void;
}

const EventDetailsMap: FC<EventDetailsMapProps> = ({
  event,
  eventLocked,
  handleEdit,
}) => {
  // prettier-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  (mapboxgl as any).workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

  const targetRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapRef>(null);
  const [size, setSize] = useState<[number, number]>([0, 0]);
  const [viewport, setViewport] = useState<MapProps>({
    latitude: 40,
    longitude: -100,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });
  const [assetLayer, setAssetLayer] = useState<any>({});

  const hasLocation = useMemo(() => Boolean(event.latitude), [event.latitude]);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (targetRef.current && hasLocation) {
        const width = targetRef.current.offsetWidth;
        setSize([width, targetRef.current.offsetHeight]);
      }
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [hasLocation]);

  useEffect(() => {
    if (!_.isEmpty(event)) {
      if (_.last(event.entityAssets)?.assetId) {
        getAssetLayerGeojson(_.last(event.entityAssets)?.assetId as number);
      }

      setViewport((prev) => ({
        ...prev,
        latitude: event.latitude,
        longitude: event.longitude,
      }));
    }
  }, [event]);

  const getAssetLayerGeojson = (assetId: number) => {
    if (assetId) {
      getAssetLayerById(assetId).then((res) => {
        if (!_.isEmpty(res.data.geojson)) {
          setAssetLayer(JSON.parse(res.data.geojson));
        }
      });
    }
  };

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);

  return (
    <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
      <CardHeader
        className="lynx-card-header"
        title="Location"
        action={
          userRoleCanSave &&
          (eventLocked ? (
            <LockIcon className="mt-3 mr-3" color="disabled" fontSize="small" />
          ) : (
            <Button
              onClick={() => handleEdit("location")}
              disabled={eventLocked}
            >
              Edit
            </Button>
          ))
        }
      />

      <CardContent className="p-0">
        <div
          style={{
            height: hasLocation ? "300px" : "100%",
            width: "100%",
          }}
          ref={targetRef}
        >
          {hasLocation ? (
            <Map
              {...viewport}
              mapStyle={"mapbox://styles/mapbox/light-v11"}
              ref={mapRef}
              style={{ width: size[0], height: size[1] }}
              mapboxAccessToken={MAPBOX_TOKEN}
              onMove={(nextViewport) => setViewport(nextViewport.viewState)}
            >
              <NavigationControl style={{ right: 10, top: 10 }} />
              {event.latitude && event.longitude && (
                <Marker longitude={event.longitude} latitude={event.latitude}>
                  <Pin size={20} />
                </Marker>
              )}
              {!_.isEmpty(assetLayer) && (
                <>
                  <Source type="geojson" data={assetLayer}>
                    <Layer
                      type="fill"
                      filter={["==", "$type", "Polygon"]}
                      paint={{
                        "fill-color": lynxColors.harvestOrange,
                        "fill-opacity": 0.3,
                      }}
                    />
                  </Source>
                  <Source type="geojson" data={assetLayer}>
                    <Layer
                      type="line"
                      filter={["==", "$type", "LineString"]}
                      paint={{
                        "line-color": lynxColors.harvestOrange,
                      }}
                    />
                  </Source>
                  <Source type="geojson" data={assetLayer}>
                    <Layer
                      type="circle"
                      filter={["==", "$type", "Point"]}
                      paint={{
                        "circle-color": lynxColors.harvestOrange,
                      }}
                    />
                  </Source>
                </>
              )}
            </Map>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                height: "inherit",
                padding: 2,
              }}
            >
              No associated location
            </Box>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default EventDetailsMap;
