import * as React from "react";
import cn from "classnames";
import Loader from "../loader/loader";
import DimmerContent from "./dimmer-content";

type Props = {
  children?: React.ReactNode;
  className?: string;
  active?: boolean;
  loader?: boolean;
};

const Dimmer: React.FC<Props> = ({ className, children, active, loader }) => {
  const classes = cn({ dimmer: true, active: active }, className);

  return (
    <div className={classes}>
      <React.Fragment>
        {loader && <Loader />}
        <DimmerContent>{children}</DimmerContent>
      </React.Fragment>
    </div>
  );
};

export default Dimmer;
