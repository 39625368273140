import { saveAs } from "file-saver";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { Card, Dimmer } from "components/lynx-components";
import lynxColors from "../../../../modules/lynxColors";
import { chartService } from "./../../../../services/chart-service";
import {
  getSystemDashboardWidgetData,
  getWidgetData,
} from "./../../../../services/dashboard-widget-service";
import { TimeFilterButton } from "./../buttons";
import { DashboardWidgetHeader } from "./dashboard-widget-header";
import "./donut-chart-widget.css";
import { WidgetTable } from "./widget-table";

export function BarChartWidget(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [widgetProperties, setWidgetProperties] = useState({});
  const [properties, setProperties] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [lastCalculatedDate, setLastCalculatedDate] = useState("");
  const [getPng, { ref }] = useCurrentPng();
  const handleDownload = useCallback(async () => {
    const png = await getPng();

    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      saveAs(png, `${getTitle()}.png`);
    }
  }, [getPng]);
  useEffect(() => {
    if (!_.isEmpty(props.systemWidget)) {
      setWidgetProperties(JSON.parse(props.systemWidget.propertiesJson));
      setFilterOptions({
        eventStatusFilter: "All",
        dateFilter: "AllTime",
        emissionsScopeFilter: "All",
      });
      loadSystemWidgetData();
    }
  }, [props.systemWidget]);

  useEffect(() => {
    if (!_.isEmpty(props.widget)) {
      setProperties(JSON.parse(props.widget.propertiesJson));
      if (props.widget.dashboardSystemWidgetId > 0) {
        setIsLoading(true);
        setFilterOptions({
          eventStatusFilter: "All",
          dateFilter: "AllTime",
          emissionsScopeFilter: "All",
        });
        loadSystemWidgetData();
      } else {
        loadUserWidgetData();
      }
    }
  }, [props.widget]);

  useEffect(() => {
    if (props.data) {
      let obj = [...props.data];
      obj.forEach((o, i) => {
        o.value = parseInt(o.value);
        o.color = chartService.getChartColorByIndex(i);
      });
      setChartData(obj);
      setIsLoading(false);
    }
  }, [props.data]);

  const loadUserWidgetData = (refreshData = false) => {
    setIsLoading(true);
    getWidgetData(props.widget.id, refreshData)
      .then((res) => {
        setLastCalculatedDate(res.data.lastCalculatedDateTimeUtc);
        let obj = JSON.parse(res.data.dataJson);
        obj.forEach((o, i) => {
          o.value = parseInt(o.value);
          o.color = chartService.getChartColorByIndex(i);
        });
        setChartData(obj);

        setIsLoading(false);
      })
      .catch((err) => {
        setChartData([]);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
      });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {`${payload[0].payload.name} : ${payload[0].value}`}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!_.isEmpty(filterOptions)) {
      loadSystemWidgetData(
        filterOptions.eventStatusFilter,
        filterOptions.dateFilter,
        filterOptions.emissionsScopeFilter,
        true
      );
    }
  }, [filterOptions]);

  const handleRefresh = () => {
    loadUserWidgetData(true);
  };

  const getTitle = () => {
    return props.systemWidgetTitle
      ? props.systemWidgetTitle
      : props.systemWidget
      ? props.systemWidget.name
      : props.title
      ? props.title
      : props.widget
      ? props.widget.title
      : "";
  };

  const loadSystemWidgetData = (
    eventStatusFilter,
    dateFilter,
    emissionsScopeFilter,
    skipLoading
  ) => {
    if (!skipLoading) {
      setIsLoading(true);
    }

    getSystemDashboardWidgetData(
      props.systemWidget
        ? props.systemWidget.id
        : props.widget.dashboardSystemWidgetId,
      eventStatusFilter,
      dateFilter,
      emissionsScopeFilter,
      null,
      null,
      null,
      null,
      null
    )
      .then((res) => {
        setWidgetProperties(JSON.parse(res.data.propertiesJson));
        let data = res.data.data;
        data.forEach(function (element, index) {
          for (const [key, value] of Object.entries(element)) {
            if (key !== "name") {
              element[key] = _.toNumber(value);
            }
          }
          element.color = chartService.getChartColorByIndex(index);
        });
        setChartData(data);

        setIsLoading(false);
      })
      .catch((err) => {
        setChartData([]);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
      });
  };

  const RenderXTick = (tickProps) => {
    const { x, y, payload, setting } = tickProps;

    let xLabel = payload.value;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={12}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          className="bar-tick-text"
        >
          {xLabel.length > 12 ? xLabel.slice(0, 12) + "..." : xLabel}
        </text>
      </g>
    );
  };

  return (
    <Card>
      <DashboardWidgetHeader
        handleExportWidget={handleDownload}
        handleSortChange={props.handleSortChange}
        handleDelete={props.handleDelete}
        upDisabled={props.upDisabled}
        downDisabled={props.downDisabled}
        widget={props.widget}
        handleRefresh={handleRefresh}
        lastCalculatedDate={lastCalculatedDate}
        title={getTitle()}
      />
      <Dimmer active={props.loading ?? isLoading} loader>
        <div style={{ width: "100%", height: 400 }}>
          {!isLoading && _.isEmpty(chartData) && (
            <h4 className="pt-4 pl-4">
              {errorMessage || "No data found for criteria"}
            </h4>
          )}
          {!_.isEmpty(chartData) && (
            <ResponsiveContainer>
              <BarChart data={chartData} ref={ref}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  interval={0}
                  dataKey={"name"}
                  tick={<RenderXTick />}
                  height={80}
                />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey={"value"} fill={lynxColors.harvestOrange}>
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </Dimmer>
      {widgetProperties.hasDateFilter && (
        <Card.Footer>
          <div className="float-right">
            <TimeFilterButton
              filterValue={filterOptions.dateFilter}
              handleTimeSelected={(option) => {
                let newOptions = { ...filterOptions };
                newOptions.dateFilter = option.value;
                setFilterOptions(newOptions);
              }}
            />
          </div>
        </Card.Footer>
      )}

      <Card.Footer className="p-0">
        {!isLoading && !_.isEmpty(chartData) && (
          <WidgetTable
            chartData={chartData}
            properties={properties}
            history={props.history}
            widget={props.widget}
            setActiveIndex={() => {}}
          />
        )}
      </Card.Footer>
    </Card>
  );
}
