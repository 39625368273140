import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MultiSelect } from "components/form-controls/multi-select";
import _ from "lodash";
import lynxColors from "modules/lynxColors";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Grid } from "components/lynx-components";
import { IncidentAssignmentStages } from "types/enums";
import { validationService } from "./../../../../services/validation";
import {
  useAddIncidentWorkflowSettingMutation,
  useDeleteIncidentWorkflowSettingMutation,
  useUpdateIncidentWorkflowSettingMutation,
} from "services/rtkApi/endpoints/incidentWorkflowSettings";
import { LynxDialog } from "components/lynx-dialog";
import useAlert from "hooks/useAlert";
export function IncidentAssignmentSettingModal(props) {
  const initalFrom = {
    stage: "",
    stageError: "",
    appliesToAllIncidents: false,
    appliesToAllUsers: false,
    appliesToAllActions: false,
    assignDepartmentHeads: false,
    assignSubmitter: false,
    assignInitialReviewer: false,
    assignInvestigationLead: false,
    assignInvestigationTeam: false,
    assignFinalReviewer: false,
    assignActionCreator: false,
    assignActionAssignedTo: false,
    assignActionApprover: false,
    assignActionVerifier: false,
    selectedIncidentTypesError: "",
    selectedSeveritiesError: "",
    selectedActionPrioritiesError: "",
    selectedUsersError: "",
    isAssignment: true,
  };
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [formState, setFormState] = useState(initalFrom);
  const { users } = useSelector((state) => state.lookups);
  const [selectedIncidentTypes, setSelectedIncidentTypes] = useState([]);
  const [selectedSeverities, setSelectedSeverities] = useState([]);
  const [selectedActionPriorities, setSelectedActionPriorities] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const actionPriorities = ["P1", "P2", "P3", "P4", "P5"];
  const [addTrigger] = useAddIncidentWorkflowSettingMutation();
  const [updateTrigger] = useUpdateIncidentWorkflowSettingMutation();
  const [deleteTrigger] = useDeleteIncidentWorkflowSettingMutation();
  const { showAlert } = useAlert();
  useEffect(() => {
    if (
      !_.isEmpty(props.selectedIncidentAssignmentSetting) &&
      !_.isEmpty(props.incidentSeverities) &&
      !_.isEmpty(props.incidentTypes) &&
      !users.isLoading
    ) {
      setFormStateFromProps(props.selectedIncidentAssignmentSetting);
    }
  }, [
    props.selectedIncidentAssignmentSetting,
    props.incidentSeverities,
    props.incidentTypes,
    users,
  ]);
  const isActionWorkflow =
    formState.stage &&
    (formState.stage ==
      IncidentAssignmentStages.IncidentActionApprovalAssigned ||
      formState.stage ==
        IncidentAssignmentStages.IncidentActionVerificationAssigned);

  const setFormStateFromProps = (setting) => {
    setFormState({
      stage: setting.stage,
      appliesToAllIncidents: setting.appliesToAllIncidents,
      appliesToAllUsers: setting.appliesToAllUsers,
      appliesToAllActions: setting.appliesToAllActions,
      assignDepartmentHeads: setting.assignDepartmentHeads,
      assignSubmitter: setting.assignSubmitter,
      assignInitialReviewer: setting.assignInitialReviewer,
      assignInvestigationLead: setting.assignInvestigationLead,
      assignInvestigationTeam: setting.assignInvestigationTeam,
      assignFinalReviewer: setting.assignFinalReviewer,
      assignActionCreator: setting.assignActionCreator,
      assignActionAssignedTo: setting.assignActionAssignedTo,
      assignActionApprover: setting.assignActionApprover,
      assignActionVerifier: setting.assignActionVerifier,
      name: setting.name,
    });
    var usersToSet = users.filter((x) => setting.userIds.includes(x.id));
    setSelectedUsers(usersToSet);
    var incidentTypesToSet = props.incidentTypes.filter((x) =>
      setting.lookupIds.includes(x.id)
    );
    setSelectedIncidentTypes(incidentTypesToSet);
    var incidentSeveritiesToSet = props.incidentSeverities.filter((x) =>
      setting.lookupIds.includes(x.id)
    );
    setSelectedSeverities(incidentSeveritiesToSet);
    setSelectedActionPriorities(setting.actionPriorities);
  };
  const userOptions = [
    {
      stage: IncidentAssignmentStages.IncidentInitialReviewAssigned,
      options: ["assignDepartmentHeads", "assignSubmitter"],
    },
    {
      stage: IncidentAssignmentStages.IncidentFinalReviewAssigned,
      options: [
        "assignDepartmentHeads",
        "assignSubmitter",
        "assignInitialReviewer",
        "assignInvestigationLead",
        "assignInvestigationTeam",
      ],
    },
    {
      stage: IncidentAssignmentStages.IncidentActionApprovalAssigned,
      options: [
        "assignDepartmentHeads",
        "assignSubmitter",
        "assignInitialReviewer",
        "assignInvestigationLead",
        "assignInvestigationTeam",
        "assignActionCreator",
      ],
    },

    {
      stage: IncidentAssignmentStages.IncidentActionVerificationAssigned,
      options: [
        "assignDepartmentHeads",
        "assignSubmitter",
        "assignInitialReviewer",
        "assignInvestigationLead",
        "assignInvestigationTeam",
        "assignActionCreator",
        "assignActionApprover",
        "assignActionAssignedTo",
      ],
    },
  ];

  const handleDelete = () => {
    deleteTrigger(props.selectedIncidentAssignmentSetting.id).then((res) => {
      showAlert("success", `Incident assignment workflow deleted.`);
      props.handleClose();
    });
  };

  const handleSave = () => {
    if (!validateDataForSave()) {
      return;
    }

    let dtoToSave = { ...formState };
    dtoToSave.actionPriorities = selectedActionPriorities;
    dtoToSave.userIds = selectedUsers.map((a) => a.id);
    dtoToSave.lookupIds = [
      ...selectedIncidentTypes.map((a) => a.id),
      ...selectedSeverities.map((a) => a.id),
    ];
    if (props.selectedIncidentAssignmentSetting) {
      updateTrigger({
        id: props.selectedIncidentAssignmentSetting.id,
        body: dtoToSave,
      }).then((res) => {
        if (res.data) {
          showAlert("success", `Incident assignment workflow updated.`);
          props.handleClose();
        }

        if (res.error) {
          showAlert("error", res.error?.data?.message);
        }
      });
    } else {
      addTrigger(dtoToSave).then((res) => {
        if (res.data) {
          showAlert("success", `Incident assignment workflow created.`);
          props.handleClose();
        }

        if (res.error) {
          showAlert("error", res.error?.data?.message);
        }
      });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = true;

    if (!formState.appliesToAllIncidents) {
      if (_.isEmpty(selectedIncidentTypes)) {
        newState.selectedIncidentTypesError =
          "One or more incident types are required.";
        isFormValid = false;
      } else {
        newState.selectedIncidentTypesError = "";
      }
      if (_.isEmpty(selectedSeverities)) {
        newState.selectedSeveritiesError =
          "One or more incident severities are required.";
        isFormValid = false;
      } else {
        newState.selectedSeveritiesError = "";
      }
    } else {
      newState.selectedIncidentTypesError = "";
      newState.selectedSeveritiesError = "";
    }
    if (isActionWorkflow) {
      if (!formState.appliesToAllIncidents) {
        if (_.isEmpty(selectedActionPriorities)) {
          newState.selectedActionPrioritiesError =
            "One or more action priorities are required.";
          isFormValid = false;
        }
      } else {
        newState.selectedActionPrioritiesError = "";
      }
    } else {
      newState.selectedActionPrioritiesError = "";
    }

    var newOptions = formState.stage
      ? userOptions.find((a) => a.stage == formState.stage).options
      : [];

    if (!formState.appliesToAllUsers) {
      if (_.isEmpty(selectedUsers)) {
        var optionSelected = false;
        newOptions.forEach((option) => {
          if (formState[option] == true) {
            optionSelected = true;
          }
        });
        if (!optionSelected) {
          newState.selectedUsersError = "One or more users are required.";
        } else {
          newState.selectedUsersError = "";
        }
      } else {
        newState.selectedUsersError = "";
      }
    } else {
      newState.selectedUsersError = "";
    }
    validationService.validateRequiredField(
      newState,
      "stage",
      "stageError",
      "Workflow Stage"
    );

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    if (isFormValid) {
      isFormValid = !validationService.hasError(newState, [
        "nameError, stageError",
      ]);
    }

    if (!isFormValid) {
      setFormState(newState);
      showAlert("error", "Form is not valid for saving.");
    }
    return isFormValid;
  };
  const handleInputChange = (e) => {
    let newState = { ...formState };
    let { name } = e.target;
    let value = e.target.type == "checkbox" ? e.target.checked : e.target.value;
    if (name == "stage") {
      newState = resetFields(value, newState);
    }
    _.set(newState, name, value);
    setFormState(newState);
  };

  const resetFields = (value, newState) => {
    var option = userOptions.find((a) => a.stage == value);
    if (option == null) {
      return newState;
    }
    Object.keys(newState).forEach((key) => {
      if (_.startsWith(key, "assign") && !option.options.includes(key)) {
        newState[key] = false;
      }
    });
    return newState;
  };
  return (
    <Dialog
      open={props.open}
      maxWidth={"lg"}
      fullWidth={true}
      onClose={props.handleClose}
      className="lynx-dialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.selectedIncidentAssignmentSetting ? "Edit" : "Add"} Incident
        Assignment Workflow
      </DialogTitle>

      <DialogContent dividers={props.dividers}>
        <Grid>
          <Grid.Row>
            <Grid.Col width={6}>
              <Typography className="mb-2">
                Select the assignment task:
                <span className="form-required">*</span>
              </Typography>
              <Form.Group label="">
                <Form.Select
                  name={`stage`}
                  onChange={handleInputChange}
                  value={formState.stage}
                  error={formState.stageError}
                >
                  <option value={""}></option>
                  <option
                    value={
                      IncidentAssignmentStages.IncidentInitialReviewAssigned
                    }
                  >
                    Incident Initial Review
                  </option>
                  <option
                    value={IncidentAssignmentStages.IncidentFinalReviewAssigned}
                  >
                    Incident Final Review
                  </option>
                  <option
                    value={
                      IncidentAssignmentStages.IncidentActionApprovalAssigned
                    }
                  >
                    Action Approval
                  </option>
                  <option
                    value={
                      IncidentAssignmentStages.IncidentActionVerificationAssigned
                    }
                  >
                    Action Verification
                  </option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          {formState.stage && (
            <>
              <Box
                sx={{
                  border: `1px solid ${lynxColors.gray}`,
                  padding: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Typography className="mb-2">
                      Select the incident types and severites to apply the
                      assignment(s) to:
                      <span className="form-required">*</span>
                    </Typography>
                    <Form.Group label="">
                      <Form.Checkbox
                        label="Applies to all incidents"
                        name="appliesToAllIncidents"
                        checked={formState.appliesToAllIncidents}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                {!formState.appliesToAllIncidents && (
                  <>
                    <Grid.Row>
                      <Grid.Col md={6} width={12}>
                        <Form.Group
                          label={"Select incident types"}
                          isRequired={true}
                        >
                          <MultiSelect
                            name="incidentTypes"
                            onChange={(e) => {
                              setSelectedIncidentTypes(e.target.value);
                            }}
                            value={selectedIncidentTypes}
                            dropdownValues={props.incidentTypes}
                            key="id"
                            label="code"
                            error={formState.selectedIncidentTypesError}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <Form.Group
                          label={"Select severities"}
                          isRequired={true}
                        >
                          <MultiSelect
                            name="severities"
                            onChange={(e) => {
                              setSelectedSeverities(e.target.value);
                            }}
                            value={selectedSeverities}
                            dropdownValues={props.incidentSeverities}
                            key="id"
                            label="code"
                            error={formState.selectedSeveritiesError}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </>
                )}
              </Box>
              {formState.stage && isActionWorkflow && (
                <Box
                  sx={{
                    border: `1px solid ${lynxColors.gray}`,
                    padding: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Grid.Row>
                    <Grid.Col width={12}>
                      <Typography className="mb-2">
                        Select the action priorities to apply to this task:
                        <span className="form-required">*</span>
                      </Typography>
                      <Form.Group label="">
                        <Form.Checkbox
                          label="Applies to all actions"
                          name="appliesToAllActions"
                          checked={formState.appliesToAllActions}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  {!formState.appliesToAllActions && (
                    <>
                      <Grid.Row>
                        <Grid.Col md={6} width={12}>
                          <Form.Group
                            label={"Select action priorities"}
                            isRequired={true}
                          >
                            <MultiSelect
                              name="actionPriorities"
                              onChange={(e) => {
                                setSelectedActionPriorities(e.target.value);
                              }}
                              error={formState.selectedActionPrioritiesError}
                              value={selectedActionPriorities}
                              dropdownValues={actionPriorities}
                            />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                    </>
                  )}
                </Box>
              )}
              <Box
                sx={{
                  border: `1px solid ${lynxColors.gray}`,
                  padding: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Typography className="mb-2">
                      Select the users and user types to assign to this task:
                      <span className="form-required">*</span>
                    </Typography>
                    <Form.Group label="">
                      <Form.Checkbox
                        label="Applies to all users"
                        name="appliesToAllUsers"
                        checked={formState.appliesToAllUsers}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                {!formState.appliesToAllUsers && (
                  <Grid.Row>
                    <Grid.Col md={6} width={12}>
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignDepartmentHeads") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Department Head(s)"
                              name="assignDepartmentHeads"
                              checked={formState.assignDepartmentHeads}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignSubmitter") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Incident Submitter"
                              name="assignSubmitter"
                              checked={formState.assignSubmitter}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignInitialReviewer") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Incident Initial Reviewer"
                              name="assignInitialReviewer"
                              checked={formState.assignInitialReviewer}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignInvestigationLead") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Investigation Lead"
                              name="assignInvestigationLead"
                              checked={formState.assignInvestigationLead}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignInvestigationTeam") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Investigation Team Members"
                              name="assignInvestigationTeam"
                              checked={formState.assignInvestigationTeam}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignFinalReviewer") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Incident Final Reviewer"
                              name="assignFinalReviewer"
                              checked={formState.assignFinalReviewer}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignActionCreator") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Action Creator"
                              name="assignActionCreator"
                              checked={formState.assignActionCreator}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignActionAssignedTo") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Action Assigned To"
                              name="assignActionAssignedTo"
                              checked={formState.assignActionAssignedTo}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignActionApprover") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Action Approver"
                              name="assignActionApprover"
                              checked={formState.assignActionApprover}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                      {formState.stage &&
                        userOptions
                          .find((a) => a.stage == formState.stage)
                          .options.includes("assignActionVerifier") && (
                          <Form.Group label="">
                            <Form.Checkbox
                              label="Action Verifier"
                              name="assignActionVerifier"
                              checked={formState.assignActionVerifier}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        )}
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group label={"Select Users"}>
                        <MultiSelect
                          name="users"
                          error={formState.selectedUsersError}
                          onChange={(e) => {
                            setSelectedUsers(e.target.value);
                          }}
                          value={selectedUsers}
                          dropdownValues={users}
                          key="id"
                          label="fullName"
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                )}
              </Box>
              <Box
                sx={{
                  border: `1px solid ${lynxColors.gray}`,
                  padding: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Typography className="mb-2">
                      Name this workflow:
                      <span className="form-required">*</span>
                    </Typography>
                    <Form.Group label="">
                      <Form.Input
                        type="text"
                        name="name"
                        onChange={handleInputChange}
                        value={formState.name}
                        error={formState.nameError}
                      ></Form.Input>
                      <Typography variant="caption">
                        i.e. Assign Department Heads to Initial Review
                      </Typography>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Box>
            </>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.handleClose} color={"error"} variant="contained">
          Cancel
        </Button>

        {props.selectedIncidentAssignmentSetting && (
          <Button
            onClick={() => setShowDeleteWarning(true)}
            variant="contained"
            color="error"
            className="confirm-delete-button"
          >
            Delete
          </Button>
        )}

        <Button
          onClick={handleSave}
          variant="contained"
          sx={{ color: "white" }}
        >
          {props.selectedIncidentAssignmentSetting ? "Save" : "Add"}
        </Button>
      </DialogActions>
      {showDeleteWarning && (
        <LynxDialog
          open={showDeleteWarning}
          handleConfirm={handleDelete}
          handleClose={() => setShowDeleteWarning(false)}
          title="Are you sure you want to delete?"
          description="This cannot be undone."
        />
      )}
    </Dialog>
  );
}
