import React from "react";
import { EntityTypes } from "types/enums";
import { getUserHistory } from "../../../../services/organization-portal-service";
import { LynxDataGrid } from "../../../data-grid/LynxDataGrid";
import { userHistoryColumns } from "./user-history-columns";
export function OrganizationPortalHistory(props) {
  return (
    <LynxDataGrid
      hasNoLookups
      title="Organization Portal User History"
      columns={userHistoryColumns}
      getDataFunction={getUserHistory}
      localStorageName="org_portal_user_history"
      entityName={EntityTypes.OrganizationPortalUserHistory}
      {...props}
    />
  );
}
