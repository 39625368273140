import * as React from "react";
import cn from "classnames";

import "./container.css";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Container: React.FC<Props> = ({ className, children }) => {
  const classes = cn("container", className);
  return <div className={classes}>{children}</div>;
};

export default Container;
