import moment from "moment";
export class DateUtil {
  convertDateTimeToLocal(dateTime, withSeconds = false) {
    if (!dateTime) {
      return "";
    }

    return moment
      .utc(dateTime)
      .local()
      .format(withSeconds ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD HH:mm");
  }
  convertDateTimeToLocalDateOnly(dateTime) {
    if (!dateTime) {
      return "";
    }

    return moment.utc(dateTime).local().format("YYYY-MM-DD");
  }
  convertDateOnlyToLocal(dateOnly) {
    if (!dateOnly) {
      return "";
    }
    return dateOnly;
  }
}

export const dateUtil = new DateUtil();
