import _ from "lodash";
import { getAssetLookups } from "./assets";
import { getCustomFields } from "./custom-fields-service";
import { getLookups } from "./lookup";
import { getParameters } from "./parameters";
import { getMonitoringLocations } from "./monitoring-location-service";
import { getMonitoringLimits } from "./monitoring-limits";
import { getMonitoringEvents } from "./monitoring-events";
import { getEvents } from "./events";
import { createEventWithAttachments } from "./events";
import { createMonitoringEvent } from "./monitoring-events";
import localforage from "localforage";
import { getUsersLookups } from "./users";
import moment from "moment";
import { EntityTypes, LookupTypes, UserRoles } from "../types/enums";
import { getInspectionForms } from "./inspection-form-service";
import { getEquipment } from "./equipment-service";
import { createInspection, getInspections } from "./inspection-event-service";
import { hasAnyEventRole, roleMatch } from "../actions/auth";
import { getLastSyncCacheInvalidated } from "./organization-service";

export const assetStore = "assetStore_v2";
export const inspectionFormStore = "inspectionFormStore_v2";
export const equipmentStore = "equipmentStore_v2";
export const categoryStore = "categoryStore_v2";
export const subCategoryStore = "subCategoryStore_v2";
export const customFieldStore = "customFieldStore_v2";
export const monLocStoreName = "monitoringLocationStore_v2";
export const unitStoreName = "unitStore_v2";
export const resultTypeStoreName = "resultTypeStore_v2";
export const eventTypeStoreName = "eventTypeStore_v2";
export const parameterStoreName = "parameterStore_v2";
export const monLimitStore = "monLimitStore_v2";
export const eventStore = "eventStore_v2";
export const actionStore = "actionStore_v2";
export const userStore = "userStore_v2";
export const inspectionEventStore = "inspectionEventStore_v2";
export const monitoringEventsStore = "monitoringEventsStore_v2";
export const eventSyncStore = "eventSyncStore_v2";
export const lastSyncName = "lastSyncDateTime";
export const actionCategoryStoreName = "actionCategoryStore_v2";
export const actionEvidenceRequiredStoreName = "actionEvidenceRequired_v2";
export const monitoringCustomFieldStoreName = "monitoringCustomFieldStore_v2";

class OfflineDataService {
  async handleSyncEvents() {
    var deleteFromSyncDb = function (id) {
      localforage.getItem(eventSyncStore).then((res) => {
        let data = [...res];
        const index = data.find((x) => x.id == id);
        data.splice(index, 1);
        localforage.setItem(eventSyncStore, data);
      });
    };
    //send events to server
    return new Promise(function (resolve) {
      let packages = [];
      localforage.getItem(eventSyncStore).then(async (res) => {
        if (res == null || res == undefined) {
          resolve(true);
          return;
        }
        const totalEventsToSync = res.length;
        if (totalEventsToSync == 0) {
          resolve(true);
          return;
        }
        res.forEach((result) => {
          let form = new FormData();
          if (!_.isEmpty(result.attachments)) {
            for (var index = 0; index < result.attachments.length; index++) {
              var element = result.attachments[index];
              form.append("file", new File([element.blob], element.name));
            }
          }
          form.append("data", JSON.stringify(result.form));
          let item = {
            form: form,
            result: result,
          };
          packages.push(item);
        });
        if (packages.length > 0) {
          for (let i = 0; i < packages.length; i++) {
            if (packages[i].result.type == "monitoring") {
              try {
                const response = await createEventWithAttachments(
                  packages[i].form
                );
                deleteFromSyncDb(packages[i].result.id);
              } catch (err) {
                console.log(err);
              }
            } else if (packages[i].result.type == "event") {
              try {
                const result = await createMonitoringEvent(packages[i].form);
                deleteFromSyncDb(packages[i].result.id);
              } catch (err) {
                console.log(err);
              }
            } else if (packages[i].result.type == "inspection-event") {
              try {
                const result = await createInspection(packages[i].form);
                deleteFromSyncDb(packages[i].result.id);
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
        resolve(true);
        return;
      });
    });
  }

  async checkIfOfflineIsReady() {
    return new Promise(function (resolve) {
      getLastSyncCacheInvalidated().then((res) => {
        const lastSyncInvalidDate = moment
          .utc(res.data)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        localforage.getItem(lastSyncName).then((res) => {
          if (res != null && res != undefined) {
            const lastSyncDate = moment
              .utc(res)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
            if (new Date(lastSyncInvalidDate) > new Date(lastSyncDate)) {
              resolve(false);
            }
          } else {
            resolve(false);
          }
          localforage.getItem(assetStore).then((value) => {
            if (value == null) resolve(false);
            localforage.getItem(customFieldStore).then((value) => {
              if (value == null) resolve(false);
              localforage
                .getItem(monitoringCustomFieldStoreName)
                .then((value) => {
                  if (value == null) resolve(false);
                  localforage.getItem(categoryStore).then((value) => {
                    if (value == null) resolve(false);
                    localforage.getItem(subCategoryStore).then((value) => {
                      if (value == null) resolve(false);
                      localforage.getItem(unitStoreName).then((value) => {
                        if (value == null) resolve(false);
                        localforage
                          .getItem(resultTypeStoreName)
                          .then((value) => {
                            if (value == null) resolve(false);
                            localforage
                              .getItem(parameterStoreName)
                              .then((value) => {
                                if (value == null) resolve(false);
                                localforage
                                  .getItem(eventTypeStoreName)
                                  .then((value) => {
                                    if (value == null) resolve(false);
                                    localforage
                                      .getItem(monLocStoreName)
                                      .then((value) => {
                                        if (value == null) resolve(false);
                                        localforage
                                          .getItem(monLimitStore)
                                          .then((value) => {
                                            if (value == null) resolve(false);
                                            localforage
                                              .getItem(monitoringEventsStore)
                                              .then((value) => {
                                                if (value == null)
                                                  resolve(false);
                                                localforage
                                                  .getItem(eventStore)
                                                  .then((value) => {
                                                    if (value == null)
                                                      resolve(false);
                                                    localforage
                                                      .getItem(userStore)
                                                      .then((value) => {
                                                        if (value == null)
                                                          resolve(false);
                                                        resolve(true);
                                                      });
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                    });
                  });
                });
            });
          });
        });
      });
    });
  }

  prepareForOffline() {
    getAssetLookups().then((res) => {
      localforage.setItem(assetStore, res.data);
    });
    getCustomFields(EntityTypes.Event).then((res) => {
      localforage.setItem(customFieldStore, res.data);
    });
    getCustomFields(EntityTypes.MonitoringEvent).then((res) => {
      localforage.setItem(monitoringCustomFieldStoreName, res.data);
    });
    getLookups("eventCategory").then((res) => {
      localforage.setItem(categoryStore, res.data);
    });
    getLookups("eventSubCategory").then((res) => {
      localforage.setItem(subCategoryStore, res.data);
    });
    getLookups("Unit").then((res) => {
      localforage.setItem(unitStoreName, res.data);
    });
    getLookups(LookupTypes.MonitoringResultType).then((res) => {
      localforage.setItem(resultTypeStoreName, res.data);
    });
    getParameters().then((res) => {
      localforage.setItem(parameterStoreName, res.data);
    });
    getLookups("MonitoringEventType").then((res) => {
      localforage.setItem(eventTypeStoreName, res.data);
    });
    getMonitoringLocations({
      pageSize: 100000,
    }).then((res) => {
      localforage.setItem(monLocStoreName, res.data);
    });
    getMonitoringLimits().then((res) => {
      localforage.setItem(monLimitStore, res.data);
    });
    getMonitoringEvents({ pageSize: 100 }).then((res) => {
      localforage.setItem(monitoringEventsStore, res.data);
    });
    getEvents({ pageSize: 100 }).then((res) => {
      localforage.setItem(eventStore, res.data);
    });
    getUsersLookups().then((res) => {
      localforage.setItem(userStore, res.data);
    });
    getInspectionForms().then((res) => {
      localforage.setItem(inspectionFormStore, res.data);
    });
    getEquipment({ pageSize: 10000 }).then((res) => {
      localforage.setItem(equipmentStore, res.data);
    });
    let utcDateTime = moment.utc().format();
    localforage.setItem(lastSyncName, utcDateTime);
  }

  reloadEvents() {
    if (roleMatch([UserRoles.MonitoringUser])) {
      getMonitoringEvents({ pageSize: 100 }).then((res) => {
        localforage.setItem(monitoringEventsStore, res.data);
      });
    }
    if (hasAnyEventRole()) {
      getEvents({ pageSize: 100 }).then((res) => {
        localforage.setItem(eventStore, res.data);
      });
    }
    if (roleMatch([UserRoles.InspectionsUser])) {
      getInspections({ pageSize: 100, onlyShowInspectionForms: true }).then(
        (res) => {
          localforage.setItem(inspectionEventStore, res.data);
        }
      );
    }
  }

  saveToIndexDb(storeName, data) {
    localforage.setItem(storeName, data);
  }
}

export const offlineDataService = new OfflineDataService();
