import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPermitExportAsXLSX, getPermits } from "../../../services/permit-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { permitColumns } from "./permit-columns";
import { PermitMobileCard } from "./permit-mobile-card";
import { EntityTypes } from "../../../types/enums";
import { transformCustomFieldDefsToColumnDefs } from "./../../../services/custom-fields-service";
import { getPermitLookups } from "../../../services/lookup";

export function PermitList(props) {
  var account = useSelector((state) => state.account);
  const [columns, setColumns] = useState([]);
  const [eventCustomFields, setEventCustomFields] = useState(null);
  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };

  useEffect(() => {
    let newColumns = [...permitColumns(predefinedLookups)];

    let colIndex = newColumns.findIndex((x) => x.field == "permitNumber");
    newColumns[colIndex].renderCell = (params) => {
      return (
        <Button
          variant="text"
          className="permit-number-button"
          onClick={() => handlePermitNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [permitColumns]);

  useEffect(() => {
    getCustomFields(EntityTypes.Permit)
      .then((res) => {
        let eventCustomFields = res.data;
        setEventCustomFields(eventCustomFields);
      })
      .catch((err) => {
        setColumns(permitColumns(predefinedLookups));
      });
  }, []);

  useEffect(() => {
    if (eventCustomFields != null && !account.isLoading) {
      let newColumns = [
        ...permitColumns(predefinedLookups),
        ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
      ];
      setColumns(newColumns);
    }
  }, [eventCustomFields, account.isLoading]);

  const navigateToAddPermit = () => {
    props.history.push(`/add-permit`);
  };

  const handlePermitNumberClick = (row) => {
    props.history.push(`/permits/${row.id}`);
  };

  return (
    <>
      <LynxDataGrid
        enableSavedFilters
        columns={columns}
        getDataFunction={getPermits}
        title="Permits"
        localStorageName="permits"
        getLookupsFunction={getPermitLookups}
        entityName={EntityTypes.Permit}
        customFields={eventCustomFields}
        addButtonAction={navigateToAddPermit}
        addButtonText="Add Permit"
        mobileCard={PermitMobileCard}
        handleEventsExportXLSX={getPermitExportAsXLSX}
        {...props}
      />
    </>
  );
}
