import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, FC } from "react";
import { Dimmer } from "components/lynx-components";
import lynxColors from "../../../modules/lynxColors";
import { getUserById } from "../../../services/users";
import { UserDto } from "types";

// Define interface for props
interface UserQuickCardProps {
  id: string;
}

const UserQuickCard: FC<UserQuickCardProps> = ({ id }) => {
  const [user, setUser] = useState<UserDto>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getUserById(id).then((res) => {
        setUser(res.data);
        setIsLoading(false);
      });
    }
  }, [id]);

  const getInitials = (fullName?: string) => {
    if (fullName) {
      return fullName
        .split(" ")
        .map((n) => n[0])
        .join("");
    }
    return "";
  };

  return (
    <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
      <CardContent>
        <div style={{ maxWidth: 400, minWidth: 200 }}>
          <Dimmer active={isLoading} loader>
            <div className="d-flex align-items-center">
              <Avatar
                src="/broken-image.jpg"
                className="mr-2"
                sx={{ bgcolor: lynxColors.harvestOrange }}
              >
                {getInitials(user?.fullName)}
              </Avatar>
              <div>
                <Typography>
                  <b>{user?.fullName}</b>
                </Typography>
                <Typography variant="body2" className="mr-1">
                  <a href={`mailto:${user?.email}`}>{user?.email}</a>
                </Typography>
              </div>
            </div>
          </Dimmer>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserQuickCard;
