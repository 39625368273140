import Button from "@mui/material/Button";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Form, Grid } from "components/lynx-components";

import Typography from "@mui/material/Typography";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";

import { Divider } from "@mui/material";
import lynxColors from "../../../modules/lynxColors";
import { CustomFieldTypes } from "../../../types/enums";
import "./inspection-form-field.css";
import { MultiSelect } from "../../form-controls/multi-select";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";

export function InspectionFormField(props) {
  let sigRef = useRef();
  const [sigTouched, setSigTouched] = useState(false);
  useEffect(() => {
    if (
      _.toLower(props.field.type) == _.toLower(CustomFieldTypes.Signature) &&
      props.signatureValue
    ) {
      sigRef.fromDataURL(props.signatureValue);
      setSigTouched(true);
      if (props.disabled) {
        sigRef.off();
      }
    }
  }, [props.field, props.value]);
  const field = props.field;

  const handleSignatureEnd = (name) => {
    setSigTouched(true);
    props.handleInputChange(null, name + "_signature", sigRef.toDataURL());
  };

  const handleClearSig = (name) => {
    props.handleInputChange(null, name + "_signature", null);
    sigRef.clear();
    setSigTouched(false);
  };
  return (
    <Grid.Col
      md={field.width == 100 ? 12 : field.width == 50 ? 6 : 12}
      width={12}
      className={
        field.type == CustomFieldTypes.Checkbox ? `align-items-end d-flex` : ""
      }
    >
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Paragraph) && (
        <Typography
          className="mb-2"
          dangerouslySetInnerHTML={{ __html: props.field.label }}
        ></Typography>
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Divider) && (
        <Divider className="mb-2" />
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Text) && (
        <Form.Group isRequired={field.isRequired} label={field.label}>
          <Form.Input
            disabled={props.disabled}
            type="text"
            name={field.name}
            onChange={(e) => {
              props.handleInputChange(e, null, null);
            }}
            value={props.value}
            error={props.error}
          ></Form.Input>
        </Form.Group>
      )}{" "}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Number) && (
        <Form.Group isRequired={field.isRequired} label={field.label}>
          <Form.Input
            disabled={props.disabled}
            type="number"
            name={field.name}
            onChange={(e) => {
              props.handleInputChange(e, null, null);
            }}
            value={props.value}
            error={props.error}
          ></Form.Input>
        </Form.Group>
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.TextArea) && (
        <Form.Group isRequired={field.isRequired} label={field.label}>
          <LynxTextArea
            autoResize
            disabled={props.disabled}
            name={field.name}
            onChange={(e) => {
              props.handleInputChange(e, null, null);
            }}
            value={props.value}
            error={props.error}
          ></LynxTextArea>
        </Form.Group>
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Checkbox) && (
        <Form.Group isRequired={field.isRequired}>
          <Form.Checkbox
            label={field.label}
            name={field.name}
            onChange={props.handleInputChange}
            checked={field && props.value}
            error={props.error}
            disabled={props.disabled}
          />
        </Form.Group>
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Date) && (
        <Form.Group isRequired={field.isRequired} label={field.label}>
          <Form.Input
            type="date"
            disabled={props.disabled}
            value={
              props.value ? moment.utc(props.value).format("YYYY-MM-DD") : ""
            }
            name={field.name}
            onChange={(e) => {
              props.handleInputChange(e, null, null);
            }}
            error={props.error}
          />
        </Form.Group>
      )}{" "}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.DateTime) && (
        <Form.Group isRequired={field.isRequired} label={field.label}>
          <Form.Input
            type="datetime-local"
            disabled={props.disabled}
            value={props.value}
            name={field.name}
            onChange={(e) => {
              props.handleInputChange(e, null, null);
            }}
            error={props.error}
          />
        </Form.Group>
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.MultiSelect) && (
        <Form.Group isRequired={field.isRequired} label={field.label}>
          <MultiSelect
            disabled={props.disabled}
            error={props.error}
            onChange={(e) => props.handleInputChange(e, null, null)}
            name={field.name}
            dropdownValues={
              field && field.dropdownValues
                ? field.dropdownValues.split("|")
                : []
            }
            isString
            value={props.value ? props.value.split("|") : []}
          />
        </Form.Group>
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Dropdown) && (
        <Form.Group isRequired={field.isRequired} label={field.label}>
          <Form.Select
            name={field.name}
            disabled={props.disabled}
            onChange={(e) => {
              props.handleInputChange(e, null, null);
            }}
            value={props.value}
            error={props.error}
          >
            <option value={""}></option>
            {field.dropdownValues.split("|").map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
      {_.toLower(field.type) == _.toLower(CustomFieldTypes.Signature) && (
        <>
          <Form.Group
            isRequired={field.isRequired}
            label={field.label}
            className="mb-2"
          >
            <Form.Input
              disabled={props.disabled}
              type="text"
              name={field.name}
              onChange={(e) => {
                props.handleInputChange(e, null, null);
              }}
              value={props.value}
              error={props.error}
            ></Form.Input>
            <label className="form-label mt-2">
              Signature{" "}
              {field.isRequired && <span className="form-required">*</span>}
            </label>

            <div
              style={{
                border: `1px solid ${lynxColors.gray}`,
                height: 200,
                margin: "0 auto",
              }}
            >
              <SignatureCanvas
                onEnd={() => handleSignatureEnd(field.name)}
                ref={(ref) => {
                  sigRef = ref;
                }}
                backgroundColor={
                  props.disabled ? lynxColors.disabled : "rgba(0,0,0,0)"
                }
                canvasProps={{
                  className: "sigCanvas",
                }}
              />
            </div>
            {props.error && (
              <span className="invalid-feedback" style={{ display: "inline" }}>
                Signature is required.
              </span>
            )}
          </Form.Group>
          {sigTouched && !props.disabled && (
            <Button
              className="mb-2 d-print-none"
              color="error"
              variant="outlined"
              onClick={() => handleClearSig(field.name)}
            >
              Clear Signature
            </Button>
          )}
        </>
      )}
    </Grid.Col>
  );
}
