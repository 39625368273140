import React from "react";
import cn from "classnames";
import TableHeader from "./tabler-header";
import TableBody from "./table-body";
import TableRow from "./table-row";
import TableCol from "./table-col";
import TableColHeader from "./table-col-header";

// Define the structure of body items
interface BodyItem {
  key: string | number;
  item: Array<{
    content?: React.ReactNode;
    className?: string;
    alignContent?: "left" | "center" | "right";
  }>;
}

interface Props extends React.HTMLProps<HTMLTableElement> {
  children?: React.ReactNode;
  className?: string;
  /**
   * Should this Table be optimized to contain Cards
   */
  cards?: boolean;
  /**
   * Give the table striped rows
   */
  striped?: boolean;
  /**
   * Make the table responsive
   */
  responsive?: boolean;
  highlightRowOnHover?: boolean;
  hasOutline?: boolean;
  verticalAlign?: "center";
  headerItems?: Array<{ content?: React.ReactNode; className?: string }>;
  bodyItems?: Array<BodyItem>;
}

const Table: React.FC<Props> & {
  Header: typeof TableHeader;
  Body: typeof TableBody;
  Row: typeof TableRow;
  Col: typeof TableCol;
  ColHeader: typeof TableColHeader;
} = ({
  className,
  children,
  cards = false,
  striped = false,
  responsive = false,
  highlightRowOnHover,
  hasOutline,
  verticalAlign,
  ...props
}) => {
  const classes = cn(
    "table",
    {
      "card-table": cards,
      "table-striped": striped,
      "table-hover": highlightRowOnHover,
      "table-outline": hasOutline,
      "table-vcenter": verticalAlign === "center",
    },
    className
  );

  const header = props.headerItems && (
    <TableHeader>
      <TableRow>
        {props.headerItems.map((item, i) => (
          <TableColHeader key={i} className={item.className}>
            {item.content}
          </TableColHeader>
        ))}
      </TableRow>
    </TableHeader>
  );

  const body = props.bodyItems && (
    <TableBody>
      {props.bodyItems.map((row, i) => (
        <TableRow key={row.key}>
          {row.item.map((col, i) => (
            <TableCol
              className={col.className}
              alignContent={col.alignContent}
              key={i}
            >
              {col.content}
            </TableCol>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );

  const table = (
    <table className={classes} {...props}>
      {header}
      {body || children}
    </table>
  );

  return !responsive ? table : <div className="table-responsive">{table}</div>;
};

// Assign static components to Table
Table.Header = TableHeader;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Col = TableCol;
Table.ColHeader = TableColHeader;

export default Table;
