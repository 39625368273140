const { apiService } = require("../apiService");

const BASE_ROUTE = "/assets";

const assetsEndpoint = apiService.injectEndpoints({
  endpoints: (builder: any) => ({
    getAssetsLookups: builder.query({
      query: (showAll: boolean | undefined = false) => `${BASE_ROUTE}/lookup?showAll=${showAll}`,
    }),
  }),
});

export const { useGetAssetsLookupsQuery } = assetsEndpoint;
