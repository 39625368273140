import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { loginUser } from "actions/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { checkPortalStatus } from "services/auth-service";
import { navigateTo } from "services/navigation-service";
import { validationService } from "services/validation";
import { BootstrapInput } from "../../bootstrap-inputs";
import { LynxDialog } from "../../lynx-dialog";
import { wylooColors } from "./wyloo-theme";
import { LocalStorageKeys } from "types/enums";
import useAlert from "hooks/useAlert";
export function WylooLoginRegister(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let { errorMessage, isAuthenticated } = useSelector((state) => state.auth);
  const { showAlert } = useAlert();
  
  useEffect(() => {
    if (isAuthenticated) {
      showAlert("success", "Logged in successfully.");
      if (props.location) {
        navigateTo(
          history,
          props.redirect ?? `/wyloo/monitoring-locations/${props.location.id}/`,
          null
        );
      } else {
        props.handleClose();
      }
    }
  }, [isAuthenticated]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registerDescription, setRegisterDescription] = useState(null);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [showRegistrationConfirmation, setShowRegistrationConfirmation] =
    useState(false);
  const handleConfirmEmail = () => {
    if (showPasswordFields) {
      const creds = {
        email: email.trim(),
        password: password.trim(),
        ipAddress: localStorage.getItem(LocalStorageKeys.Ip),
        url: location.pathname,
      };
      dispatch(loginUser(creds));

      return;
    }

    checkPortalStatus(email, location.pathname)
      .then((res) => {
        if (res.data == false) {
          setShowRegistrationConfirmation(true);
        } else if (res.data == true) {
          //show password fields
          setShowPasswordFields(true);
        }
      })
      .catch((err) => {
        setShowRegistrationConfirmation(true);
        setRegisterDescription(err.response.data.message);
        //show error message
      });
  };
  return (
    <>
      <LynxDialog
        open={props.open}
        confirmDisabled={
          !showPasswordFields
            ? !validationService.isEmailFormatValid(email)
            : password.length < 6
        }
        handleClose={() => props.handleClose()}
        title={`Register or sign in to view more ${
          props.isForWildlife ? "photos" : "details"
        }`}
        dialogContent={
          <div>
            <FormControl className="w-100" variant="standard">
              <InputLabel
                sx={{ fontWeight: 500, color: wylooColors.black }}
                shrink
                htmlFor="bootstrap-input"
              >
                Enter Email Address
              </InputLabel>
              <BootstrapInput
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></BootstrapInput>
              <Typography
                variant="caption"
                sx={{ color: wylooColors.black }}
                className="mt-1"
              >
                A valid email address is required to sign in or register.
              </Typography>
            </FormControl>
            {showPasswordFields && (
              <FormControl className="w-100 mt-2" variant="standard">
                <InputLabel
                  sx={{ fontWeight: 500, color: wylooColors.black }}
                  shrink
                  htmlFor="bootstrap-input"
                >
                  Enter Password
                </InputLabel>
                <BootstrapInput
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                ></BootstrapInput>
              </FormControl>
            )}
            <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
          </div>
        }
        handleConfirm={handleConfirmEmail}
      />
      {showRegistrationConfirmation && (
        <LynxDialog
          open={showRegistrationConfirmation}
          title={`Check your email to finish registration`}
          description={
            registerDescription ??
            `Please check your email for a link to complete your registration.`
          }
          handleConfirm={() => {
            setShowRegistrationConfirmation(false);
            setRegisterDescription(null);
            props.handleClose();
          }}
        />
      )}
    </>
  );
}
