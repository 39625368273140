import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { getInspections } from "../../../services/inspection-event-service";
import { inspectionColumns } from "./inspection-columns";
import { EntityTypes } from "../../../types/enums";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import {
  inspectionEventStore,
  offlineDataService,
} from "../../../services/offlineDataService";
export function InspectionList(props) {
  const [columns, setColumns] = useState([]);
  const [isOfflineReady, setIsOfflineReady] = useState(true);
  const isOffline = !useOnlineStatus();
  useEffect(() => {
    if (isOffline) {
      let isReady = true;

      offlineDataService.checkIfOfflineIsReady().then((res) => {
        if (!res) {
          isReady = false;
        }
        setIsOfflineReady(isReady);
      });
    }
  }, [isOffline]);

  useEffect(() => {
    let newColumns = [...inspectionColumns];
    let colIndex = newColumns.findIndex((x) => x.field == "inspectionNumber");
    newColumns[colIndex].renderCell = (params) => {
      return (
        <Button
          variant="text"
          className="inspection-number-button"
          onClick={() => handleInspectionNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };

    setColumns(newColumns);
  }, [inspectionColumns]);

  const navigateToAddInspection = () => {
    props.history.push(`/new-inspection`);
  };
  const handleInspectionNumberClick = (row) => {
    props.history.push(`/inspections/${row.id}`);
  };
  return (
    <>
      <LynxDataGrid
        columns={columns}
        enableSavedFilters
        title="Inspections"
        getDataFunction={getInspections}
        getDataParams={{
          onlyShowInspectionForms: true,
        }}
        indexDbObjectStoreName={inspectionEventStore}
        localStorageName="inspections"
        entityName={EntityTypes.InspectionEvent}
        indexDbSortColumn="inspectionNumber"
        addButtonText="Add Inspection"
        addButtonAction={navigateToAddInspection}
        isOfflineReady={isOfflineReady}
        {...props}
      />
    </>
  );
}
