import { RtkTagTypes } from "types/enums";

const { apiService } = require("../apiService");

const INCIDENT_WORKFLOW_SETTING_ROUTE = "/incidentWorkflowSettings";

const incidentWorkflowSettingEndpoints = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getIncidentWorkflowSettings: builder.query({
      query: (args) =>
        `${INCIDENT_WORKFLOW_SETTING_ROUTE}?isAssignments=${args}`,
      providesTags: [RtkTagTypes.GetIncidentWorkflowSettings],
    }),

    addIncidentWorkflowSetting: builder.mutation({
      query: (args) => ({
        url: INCIDENT_WORKFLOW_SETTING_ROUTE,
        method: "POST",
        body: args,
      }),
      invalidatesTags: [RtkTagTypes.GetIncidentWorkflowSettings],
    }),

    updateIncidentWorkflowSetting: builder.mutation({
      query: (args) => ({
        url: `${INCIDENT_WORKFLOW_SETTING_ROUTE}/${args.id}`,
        method: "PUT",
        body: args.body,
      }),
      invalidatesTags: [RtkTagTypes.GetIncidentWorkflowSettings],
    }),

    deleteIncidentWorkflowSetting: builder.mutation({
      query: (id) => ({
        url: `${INCIDENT_WORKFLOW_SETTING_ROUTE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [RtkTagTypes.GetIncidentWorkflowSettings],
    }),
  }),
});

export const {
  useGetIncidentWorkflowSettingsQuery,
  useAddIncidentWorkflowSettingMutation,
  useUpdateIncidentWorkflowSettingMutation,
  useDeleteIncidentWorkflowSettingMutation,
} = incidentWorkflowSettingEndpoints;
