import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React from "react";
import Contacts from "./contacts";
import { EntityTypes } from "../../../types/enums";
import { EventDto } from "types";

interface EventDetailsContactsProps {
  commentsLoading: boolean;
  event: EventDto;
}

const EventDetailsContacts: React.FC<EventDetailsContactsProps> = (props) => {
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader className="lynx-card-header" title="Contacts" />
      <CardContent className="p-2">
        <Contacts
          isLoading={props.commentsLoading}
          entityId={props.event.id}
          entityType={EntityTypes.Event}
        />
      </CardContent>
    </Card>
  );
};

export default EventDetailsContacts;
