import React, { useState, useEffect } from "react";
import { Icon, Grid, Card, Form } from "components/lynx-components";
import { getLookups } from "./../../../../services/lookup";
import { AutoComplete } from "../../../form-controls/auto-complete";
import Button from "@mui/material/Button";
import _ from "lodash";
import { saveNotificaitonTemplateSettings } from "../../../../services/notification-template-serivce";
import useAlert from "hooks/useAlert";

const initalFormState = {
  isEnabled: false,
  notifyAll: true,
  eventCategoryIds: [],
};

const initialOptionsState = {
  notifyForEventsAssignedToSelf: false,
  notifyForEventsCreatedBySelf: false,
  notifyAll: true,
};

export function NotificationTemplateModal(props) {
  const [categories, setCategories] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [formState, setFormState] = useState(initalFormState);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [optionsState, setOptionsState] = useState(initialOptionsState);
  const { showAlert } = useAlert();

  useEffect(() => {
    setFormStateFromProps();
    if (props.template.type.toLowerCase() == "events reviewed") {
      setOptionsStateFromProps();
    }
  }, [props.template]);

  useEffect(() => {
    if (_.toLower(props.template.entityType) == "event") {
      getLookups("eventCategory").then((res) => {
        setCategories(res.data);
      });
    }
    if (_.toLower(props.template.entityType) == "monitoringevent") {
      getLookups("monitoringEventType").then((res) => {
        setEventTypes(res.data);
      });
    }
  }, [props.template]);

  useEffect(() => {
    if (!_.isEmpty(categories) && props.template) {
      const cats = props.template.lookups.filter(
        (x) => x.lookupType.toLowerCase() == "eventcategory"
      );
      if (cats) {
        const selectedCats = categories.filter((x) =>
          cats.map((a) => a.id).includes(x.id)
        );
        setSelectedCategories(selectedCats);
      }
    }
  }, [props.template, categories]);

  useEffect(() => {
    if (!_.isEmpty(eventTypes) && props.template) {
      const types = props.template.lookups.filter(
        (x) => x.lookupType.toLowerCase() == "monitoringeventtype"
      );
      if (types) {
        const selectedTypes = eventTypes.filter((x) =>
          types.map((a) => a.id).includes(x.id)
        );
        setSelectedEventTypes(selectedTypes);
      }
    }
  }, [props.template, eventTypes]);

  const setFormStateFromProps = () => {
    let newForm = {
      notificationEnabled: props.template.notificationEnabled,
      emailEnabled: props.template.emailEnabled,
      type: props.template.type,
      notifyAll:
        _.isEmpty(
          props.template.lookups.filter(
            (x) => x.lookupType.toLowerCase() == "eventcategory"
          )
        ) &&
        _.isEmpty(
          props.template.lookups.filter(
            (x) => x.lookupType.toLowerCase() == "monitoringeventtype"
          )
        ),
    };
    setFormState(newForm);
  };

  const setOptionsStateFromProps = () => {
    let options = props.template.options;
    setOptionsState(JSON.parse(options));
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, checked } = e.target;

    _.set(newState, name, checked);
    setFormState(newState);
  };

  const handleOptionsInputChange = (e) => {
    let newState = { ...optionsState };
    const { name, checked } = e.target;
    if (name == "notifyAll" && checked == true) {
      setOptionsState(initialOptionsState);
    } else {
      _.set(newState, name, checked);

      setOptionsState(newState);
    }
  };

  const handleCategoriesSelected = (values) => {
    setSelectedCategories(values);
  };

  const handleEventTypesSelected = (values) => {
    setSelectedEventTypes(values);
  };

  const saveForm = () => {
    const formToSave = {
      ...props.template,
      notificationEnabled: formState.notificationEnabled,
      emailEnabled: formState.emailEnabled,
      lookups: formState.notifyAll
        ? []
        : [...selectedCategories, ...selectedEventTypes],
    };
    if (formToSave.type.toLowerCase() == "events reviewed") {
      let newOptionsState = { ...optionsState };
      if (
        newOptionsState.notifyForEventsAssignedToSelf == false &&
        newOptionsState.notifyForEventsCreatedBySelf == false
      ) {
        newOptionsState.notifyAll = true;
      }
      formToSave.options = JSON.stringify(newOptionsState);
    }
    saveNotificaitonTemplateSettings(formToSave).then((res) => {
      props.handleClose(res.data);
      showAlert("success", "Notification settings saved.");
    });
  };

  return (
    <Form className="card dsmodal-main lynx-modal">
      <Card.Body>
        <Card.Title>
          Edit Notification Settings
          <Icon
            name="x"
            onClick={props.handleModalClose}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Grid>
          <Grid.Row alignItems="center">
            <Grid.Col sm={6}>
              <Form.Group label="Type">{props.template.type}</Form.Group>
            </Grid.Col>
            <Grid.Col sm={6}>
              <Form.Group label="Description">
                {props.template.description}
              </Form.Group>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Form.Group>
                <Form.Checkbox
                  label="In App Notification Enabled"
                  name="notificationEnabled"
                  onChange={handleInputChange}
                  checked={formState.notificationEnabled}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col sm={12}>
              <Form.Group>
                <Form.Checkbox
                  label="Email Enabled"
                  name="emailEnabled"
                  onChange={handleInputChange}
                  checked={formState.emailEnabled}
                />
              </Form.Group>
            </Grid.Col>
            {(formState.emailEnabled || formState.notificationEnabled) && (
              <>
                <Grid.Col sm={12}>
                  <Form.Group>
                    <Form.Checkbox
                      label="Notify for all events"
                      name="notifyAll"
                      onChange={
                        formState.type.toLowerCase() != "events reviewed"
                          ? handleInputChange
                          : handleOptionsInputChange
                      }
                      checked={
                        formState.type.toLowerCase() != "events reviewed"
                          ? formState.notifyAll
                          : optionsState.notifyAll
                      }
                    />
                  </Form.Group>
                </Grid.Col>
                {!formState.notifyAll &&
                  formState.type.toLowerCase() != "events reviewed" &&
                  _.toLower(props.template.entityType) == "event" && (
                    <>
                      <Grid.Col sm={12}>
                        <Form.Label>
                          Select the categories you would like to be notified
                          for. Selecting a category will notify for all
                          assocaited subcategories.
                        </Form.Label>
                      </Grid.Col>
                      {!_.isEmpty(categories) && (
                        <Grid.Col sm={6}>
                          <AutoComplete
                            values={categories}
                            defaultSelectedValues={selectedCategories}
                            idField="id"
                            labelField="code"
                            label="Select Categories"
                            handleSelectedValuesChanged={
                              handleCategoriesSelected
                            }
                          />
                        </Grid.Col>
                      )}
                    </>
                  )}
                {!formState.notifyAll &&
                  _.toLower(props.template.entityType) == "monitoringevent" && (
                    <>
                      <Grid.Col sm={12}>
                        <Form.Label>
                          Select the event types you would like to be notified
                          for.
                        </Form.Label>
                      </Grid.Col>
                      {!_.isEmpty(eventTypes) && (
                        <Grid.Col sm={6}>
                          <AutoComplete
                            values={eventTypes}
                            defaultSelectedValues={selectedEventTypes}
                            idField="id"
                            labelField="code"
                            label="Select Event Types"
                            handleSelectedValuesChanged={
                              handleEventTypesSelected
                            }
                          />
                        </Grid.Col>
                      )}
                    </>
                  )}
                {!optionsState.notifyAll &&
                  formState.type.toLowerCase() == "events reviewed" && (
                    <>
                      <Grid.Col sm={12}>
                        <Form.Group>
                          <Form.Checkbox
                            label="Notify for events assigned to yourself"
                            name="notifyForEventsAssignedToSelf"
                            onChange={handleOptionsInputChange}
                            checked={optionsState.notifyForEventsAssignedToSelf}
                          />
                        </Form.Group>
                      </Grid.Col>{" "}
                      <Grid.Col sm={12}>
                        <Form.Group>
                          <Form.Checkbox
                            label="Notify for events you created"
                            name="notifyForEventsCreatedBySelf"
                            onChange={handleOptionsInputChange}
                            checked={optionsState.notifyForEventsCreatedBySelf}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </>
                  )}
              </>
            )}
          </Grid.Row>
        </Grid>
      </Card.Body>
      <Card.Footer>
        <Button variant="contained" className="float-right" onClick={saveForm}>
          Save
        </Button>
      </Card.Footer>
    </Form>
  );
}
