import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from "recharts";
import { Card, Dimmer } from "components/lynx-components";
import { chartService } from "./../../../../services/chart-service";

import { saveAs } from "file-saver";
import { useCurrentPng } from "recharts-to-png";
import { getWidgetData } from "../../../../services/dashboard-widget-service";
import { DashboardWidgetHeader } from "./dashboard-widget-header";
import "./donut-chart-widget.css";
import { WidgetTable } from "./widget-table";

export function DonutChartWidget(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [properties, setWidgetProperties] = useState({});
  const [lastCalculatedDate, setLastCalculatedDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [getPng, { ref }] = useCurrentPng();
  useEffect(() => {
    if (!_.isEmpty(props.widget)) {
      loadWidgetData();
    }
  }, [props.widget]);

  useEffect(() => {
    if (props.data) {
      let obj = [...props.data];
      obj.forEach((o, i) => {
        o.value = parseInt(o.value);
        o.color = chartService.getChartColorByIndex(i);
      });

      setChartData(obj);
      setIsLoading(false);
    }
  }, [props.data]);

  const loadWidgetData = (refreshData = false) => {
    setIsLoading(true);
    setWidgetProperties(JSON.parse(props.widget.propertiesJson));
    getWidgetData(props.widget.id, refreshData)
      .then((res) => {
        setLastCalculatedDate(res.data.lastCalculatedDateTimeUtc);
        let obj = JSON.parse(res.data.dataJson);
        obj.forEach((o, i) => {
          o.value = parseInt(o.value);
          o.color = chartService.getChartColorByIndex(i);
        });
        setChartData(obj);
        setIsLoading(false);
      })
      .catch((err) => {
        setChartData([]);
        setErrorMessage(err.response.data.message);
        setIsLoading(false);
      });
  };

  const handleRefresh = () => {
    loadWidgetData(true);
  };

  const onMouseEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const onMouseLeave = useCallback(
    (_) => {
      setActiveIndex(-1);
    },
    [setActiveIndex]
  );

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      saveAs(png, `${props.title}.png`);
    }
  }, [getPng]);

  return (
    <Card>
      <DashboardWidgetHeader
        handleSortChange={props.handleSortChange}
        handleDelete={props.handleDelete}
        upDisabled={props.upDisabled}
        downDisabled={props.downDisabled}
        widget={props.widget}
        handleRefresh={handleRefresh}
        lastCalculatedDate={lastCalculatedDate}
        handleExportWidget={handleDownload}
        title={props.title}
      />
      <Dimmer active={props.loading ?? isLoading} loader>
        <div style={{ width: "100%", height: 400 }}>
          {!isLoading && _.isEmpty(chartData) && (
            <h4 className="pt-4 pl-4">
              {errorMessage || "No data found for criteria"}
            </h4>
          )}
          <ResponsiveContainer>
            <PieChart ref={ref}>
              <Pie
                activeIndex={activeIndex}
                data={chartData}
                nameKey={"name"}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={"30%"}
                outerRadius={"90%"}
                fill="#8884d8"
                activeShape={renderActiveShape}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Dimmer>
      <Card.Footer className="p-0">
        {!isLoading && !_.isEmpty(chartData) && (
          <WidgetTable
            chartData={chartData}
            properties={properties}
            history={props.history}
            widget={props.widget}
            setActiveIndex={setActiveIndex}
          />
        )}
      </Card.Footer>
    </Card>
  );
}
const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {`${payload[0].name} : ${payload[0].value}`}
      </div>
    );
  }

  return null;
};
