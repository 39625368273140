import * as React from "react";
import cn from "classnames";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const PageTitle: React.FC<Props> = ({ className, children }) => {
  const classes = cn("page-title", className);
  return <h1 className={classes}>{children}</h1>;
};

PageTitle.displayName = "Page.Title";

export default PageTitle;
