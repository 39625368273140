import * as React from "react";
import FormGroup from "./form-group";
import FormLabel from "./form-label";
import FormInput from "./form-input";
import FormTextarea from "./form-textarea";
import FormSelect from "./form-select";
import FormRadio from "./form-radio";
import FormCheckbox from "./form-checkbox";
import FormSelectGroup from "./form-select-group";
import FormSelectGroupItem from "./form-select-group-item";

interface Props extends React.HTMLProps<HTMLFormElement> {
  children?: React.ReactNode;
  className?: string;
  action?: string;
  method?: string;
  autoComplete?: "on" | "off";
}

const Form: React.FC<Props> & {
  Group: typeof FormGroup;
  Label: typeof FormLabel;
  Input: typeof FormInput;
  Textarea: typeof FormTextarea;
  Select: typeof FormSelect;
  Radio: typeof FormRadio;
  Checkbox: typeof FormCheckbox;
  SelectGroup: typeof FormSelectGroup;
  SelectGroupItem: typeof FormSelectGroupItem;
} = ({
  className,
  children,
  action,
  method,
  onSubmit,
  autoComplete = "off",
}) => {
  return (
    <form
      className={className}
      onSubmit={onSubmit}
      action={action}
      method={method}
      autoComplete={autoComplete}
    >
      {children}
    </form>
  );
};

Form.Group = FormGroup;
Form.Label = FormLabel;
Form.Input = FormInput;
Form.Textarea = FormTextarea;
Form.Select = FormSelect;
Form.SelectGroup = FormSelectGroup;
Form.SelectGroupItem = FormSelectGroupItem;
Form.Radio = FormRadio;
Form.Checkbox = FormCheckbox;

export default Form;
