import * as React from "react";
import Stepper from "@mui/material/Stepper";
import EventSchedulerDetails from "./event-scheduler-details";
import EventSchedulerDates from "./event-scheduler-dates";
import { Box, Button, Typography } from "@mui/material";
import { useState, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useGetAssetsLookupsQuery } from "services/rtkApi/endpoints/assets";
import { useGetLookupsQuery } from "services/rtkApi/endpoints/lookups";
import {
  useAddEventScheduleMutation,
  useGetNumberOfEventsMutation,
} from "services/rtkApi/endpoints/events";
import { LynxDialog } from "components/lynx-dialog";
import { useHistory } from "react-router";
import { Grid } from "components/lynx-components";
import { blueGrey } from "@mui/material/colors";
import useAlert from "hooks/useAlert";
import { AssetDto, UserDto } from "types";

interface FormState {
  assets: AssetDto[];
  eventCategoryId: string;
  eventSubCategoryId: string;
  entityUsers: UserDto[];
  description: string;
  repeatEveryCount: number,
  repeatEveryGranularity: string,
  startDate: string,
  endDate: string,
  daysOfWeek: any[],
  customDates: any[],
}

interface Step {
  title: string;
  component: (props: any) => JSX.Element;
}

const steps: Step[] = [
  {
    title: "Select Assets, Category and Subcategory",
    component: (props) => <EventSchedulerDetails {...props} />,
  },
  {
    title: "Schedule Event",
    component: (props) => <EventSchedulerDates {...props} />,
  },
];

const EventScheduler: React.FC = () => {
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [eventCount, setEventCount] = useState(0);
  const activeStepIndex = steps.indexOf(activeStep);
  const isLastStep = activeStepIndex === steps.length - 1;
  const history = useHistory();

  const { data: assetsLookup } = useGetAssetsLookupsQuery();
  const { data: eventCategoryLookup } = useGetLookupsQuery({
    lookupType: "eventCategory",
  });
  const { data: eventSubCategoryLookup } = useGetLookupsQuery({
    lookupType: "eventSubCategory",
  });

  const [addEventScheduleTrigger] = useAddEventScheduleMutation();
  const [getNumberOfEventsTrigger] = useGetNumberOfEventsMutation();
  const { showAlert } = useAlert();

  const formSchema = Yup.object().shape({
    assets: Yup.array()
      .of(Yup.object())
      .label("Assets")
      .min(1, "Assets field is required"),
    eventCategoryId: Yup.string().label("Category").required(),
    eventSubCategoryId: (Yup.string()
      .label("Subcategory") as any)
      .when("eventCategoryId", {
        is: () => isSubCatRequired,
        then: Yup.string().label("Subcategory").required(),
      }),
    startDate: Yup.string().label("Start Date").required(),
    repeatEveryCount: Yup.number().label("Count").required(),
    repeatEveryGranularity: Yup.string().label("Granularity").required(),
    endDate: Yup.string().label("End Date").required(),
    daysOfWeek: Yup.array().of(Yup.string()),
    customDates: Yup.array().of(Yup.object()),
    description: Yup.string().label("Description"),
    entityUsers: Yup.array().of(Yup.object()).label("Assigned To"),
  });

  const {
    handleSubmit,
    errors,
    touched,
    handleChange,
    values,
    setFieldValue,
    handleBlur,
    isValid,
  } = useFormik<FormState>({
    enableReinitialize: true,
    validationSchema: formSchema,
    initialValues: {
      assets: [],
      eventCategoryId: "",
      eventSubCategoryId: "",
      startDate: "",
      repeatEveryCount: 1,
      repeatEveryGranularity: "week",
      endDate: "",
      daysOfWeek: [],
      customDates: [],
      description: "",
      entityUsers: [],
    },
    onSubmit: () => {}
  });

  const isSubCatRequired = useMemo(() => {
    if (eventCategoryLookup && values.eventCategoryId) {
      const cat = eventCategoryLookup.find(
        (x: any) => x.id === values.eventCategoryId
      );
      return cat ? cat.requireChildSelection : false;
    }
    return false;
  }, [eventCategoryLookup, values.eventCategoryId]);

  const handleNext = () => {
    if (isLastStep) {
      getNumberOfEventsTrigger(values).then((result) => {
        setEventCount(result.data || 0);
        setIsWarningOpen(true);
      });
    } else {
      const nextStep = steps[activeStepIndex + 1];
      setActiveStep(nextStep);
    }
  };

  const handleBack = () => {
    const nextStep = steps[activeStepIndex - 1];
    setActiveStep(nextStep);
  };

  const canGoNext = () => {
    return (
      values.assets.length > 0 &&
      values.eventCategoryId &&
      (isSubCatRequired ? values.eventSubCategoryId : true)
    );
  };

  const onSubmit = () => {
    if (isValid) {
      const finalValue = {
        ...values,
        entityUsers: values.entityUsers.map((user) => ({ userId: user.id })),
      };
      addEventScheduleTrigger(finalValue).then(() => {
        showAlert("success", "Events created.");
        history.push("/events");
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={activeStepIndex}></Stepper>
        <Box>
          {steps[activeStepIndex].component({
            activeStepIndex,
            handleSubmit,
            errors,
            touched,
            handleChange,
            values,
            setFieldValue,
            handleBlur,
            assetsLookup,
            eventCategoryLookup,
            eventSubCategoryLookup,
            isSubCatRequired,
            isValid,
          })}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStepIndex === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button onClick={handleNext} disabled={isLastStep ? !isValid : !canGoNext()}>
            {isLastStep ? "Schedule Events" : "Next"}
          </Button>
        </Box>
      </form>

      <LynxDialog
        title={`Schedule ${eventCount} Event${eventCount > 1 ? "s" : ""}?`}
        dialogContent={
          <Box minWidth={500}>
            {eventCount === 0 ? (
              "No event will be created. Please change the scheduling criteria and try again."
            ) : (
              <Box sx={{ paddingY: "10px", background: blueGrey[50] }}>
                <Grid>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Assets:</Grid.Col>
                    <Grid.Col md={9}>
                      {values.assets.map((asset) => asset.name).join(", ")}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Category:</Grid.Col>
                    <Grid.Col md={9}>
                      {eventCategoryLookup.find((cat: any) => cat.id === values.eventCategoryId)?.code || "-"}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Subcategory:</Grid.Col>
                    <Grid.Col md={9}>
                      {eventSubCategoryLookup.find((subCat: any) => subCat.id === values.eventSubCategoryId)?.code || "-"}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Assigned To:</Grid.Col>
                    <Grid.Col md={9}>
                      {values.entityUsers.map((user) => user.fullName).join(", ") || "-"}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col md={3}>Description:</Grid.Col>
                    <Grid.Col md={9}>
                      <Typography sx={{ wordBreak: "break-word" }}>
                        {values.description || "-"}
                      </Typography>
                    </Grid.Col>
                  </Grid.Row>
                </Grid>
              </Box>
            )}
          </Box>
        }
        open={isWarningOpen}
        handleCancel={() => setIsWarningOpen(false)}
        buttons={() =>
          eventCount > 0 ? (
            <Button variant="contained" color="success" onClick={onSubmit}>
              Yes
            </Button>
          ) : undefined
        }
      />
    </Box>
  );
};

export default EventScheduler;
