import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip, { chipClasses } from "@mui/material/Chip";
import MuiGrid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useEffect, useState, FC } from "react";
import { Dimmer, Grid } from "components/lynx-components";
import { LynxDialog } from "../../lynx-dialog";
import {
  createEntityTag,
  deleteEntityTag,
  getEntityTags,
  searchTags,
} from "../../../services/tag-service";
import { TagModal } from "../settings/ComplianceSettings/tag-modal";
import useAlert from "hooks/useAlert";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import TagQuickCard from "./tag-quick-card";
import { EntityLookupDto, LookupDto } from "types";

interface TagsProps {
  entityId: string;
  entityType: string;
  isLoading?: boolean;
  eventLocked?: boolean;
  handleSetTags?: (tags: LookupDto[]) => void;
}

const Tags: FC<TagsProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [existingTags, setExistingTags] = useState<EntityLookupDto[]>([]);
  const [showAddTag, setShowAddTag] = useState(false);
  const [options, setOptions] = useState<LookupDto[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTag, setSelectedTag] = useState<EntityLookupDto | null>(null);
  const [autoCompleteTagValue, setAutoCompleteTagValue] =
    useState<LookupDto | null>(null);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [autoCompleteTagInputValue, setAutoCompleteTagInputValue] =
    useState<string>("");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { showAlert } = useAlert();

  useEffect(() => {
    if (props.entityId && props.entityType && !props.isLoading) {
      getEntityTags(props.entityType, props.entityId).then((res) => {
        setExistingTags(res.data);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.entityId, props.entityType, props.isLoading]);

  useEffect(() => {
    if (autoCompleteTagInputValue === "") {
      setOptions(autoCompleteTagValue ? [autoCompleteTagValue] : []);
      return;
    }
    setTagsLoading(true);
    searchTags(autoCompleteTagInputValue).then((res) => {
      setOptions(res.data);
      setTagsLoading(false);
    });
  }, [autoCompleteTagValue, autoCompleteTagInputValue]);

  const handleTagDeleted = (tag: EntityLookupDto) => {
    const newTags = _.cloneDeep(existingTags);
    const index = newTags.findIndex((x) => x.lookupId === tag.lookupId);
    newTags.splice(index, 1);

    if (props.entityId) {
      setIsLoading(true);
      deleteEntityTag(existingTags[index].id).then((res) => {
        setExistingTags(res.data);
        setIsLoading(false);
        showAlert("success", "Associated tag removed.");
      });
    } else {
      setExistingTags([]);
      props.handleSetTags && props.handleSetTags(newTags);
      setExistingTags(newTags);
    }
  };

  const handleTagSelected = (
    e: React.SyntheticEvent,
    value: LookupDto | null
  ) => {
    if (value) {
      setAutoCompleteTagValue(null);
      const newTags = _.cloneDeep(existingTags);
      const existingTag = newTags.find((x) => x.id === value.id);

      if (!existingTag) {
        const tagsToSet: EntityLookupDto[] = [
          ...newTags,
          {
            lookupId: value.id,
            lookupCode: value.code,
            parentLookupCode: value.parentLookupCode,
          },
        ];

        if (props.entityId) {
          setIsLoading(true);
          createEntityTag({
            eventId: props.entityId,
            lookupId: value.id,
          }).then((res) => {
            setExistingTags(res.data);
            showAlert("success", "Tag added.");
            setIsLoading(false);
          });
        } else {
          setExistingTags(tagsToSet);
          props.handleSetTags && props.handleSetTags(tagsToSet);
        }
      }
    }
  };

  const autoCompleteTagInputChange = (
    e: React.SyntheticEvent,
    newValue: string,
    reason: string
  ) => {
    if (reason === "reset") {
      setAutoCompleteTagValue(null);
      return;
    } else {
      setAutoCompleteTagInputValue(newValue);
    }
  };

  const getLookupDtos = () => {
    return options.filter((x) => !existingTags.map((y) => y.id).includes(x.id));
  };

  const handleTagClick = (
    event: React.MouseEvent<HTMLElement>,
    tag: EntityLookupDto
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedTag(tag);
  };

  const handleAddTag = () => {
    setShowAddTag(false);
  };

  const isViewerOnly = roleMatch([UserRoles.EventsViewer]);
  const isContributorOnly = roleMatch([UserRoles.EventsContributor]);

  const userRoleCanSave = roleMatch([
    UserRoles.EventsAdministrator,
    UserRoles.EventsEditor,
    UserRoles.EventsContributor,
  ]);

  return isViewerOnly && _.isEmpty(existingTags) ? (
    <Box p={1}>No tags</Box>
  ) : (
    <Dimmer active={isLoading || props.isLoading} loader>
      <Grid.Row>
        {!_.isEmpty(existingTags) && (
          <Grid.Col md={12} width={12}>
            <MuiGrid container>
              {_.sortBy(existingTags, [
                (tag) => tag?.lookupCode?.toLowerCase(),
              ]).map((tag) => (
                <MuiGrid item key={tag.id} mr={1} mb={1}>
                  <Chip
                    onClick={(e) => handleTagClick(e, tag)}
                    label={`${
                      tag.parentLookupCode ? tag.parentLookupCode + " - " : ""
                    }${tag.lookupCode}`}
                    variant="outlined"
                    sx={{
                      [`& .${chipClasses.icon}`]: {
                        color: "rgba(0, 0, 0, 0.26)",
                      },
                    }}
                    {...(!props.eventLocked && userRoleCanSave
                      ? {
                          onDelete: () => handleTagDeleted(tag),
                        }
                      : {})}
                  />
                </MuiGrid>
              ))}
            </MuiGrid>
          </Grid.Col>
        )}
        {userRoleCanSave && (
          <>
            <Grid.Col md={12} width={12} className="mb-2 mt-1">
              <Autocomplete
                filterOptions={(x) => x}
                loading={tagsLoading}
                disabled={props.eventLocked}
                id="tag-grouped-search"
                options={getLookupDtos()}
                noOptionsText={
                  !autoCompleteTagInputValue
                    ? "Enter text to search"
                    : "No options"
                }
                getOptionLabel={(option) =>
                  option.code + " " + option.parentLookupCode
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.parentLookupCode && (
                      <Typography>
                        {option.parentLookupCode}&nbsp;-&nbsp;
                      </Typography>
                    )}
                    {option.code}
                    {option.childLookupCode && (
                      <Typography variant="body2">
                        &nbsp;({option.childLookupCode})
                      </Typography>
                    )}
                  </Box>
                )}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Search tags by tag, tag group, or tag category"
                  />
                )}
                onChange={handleTagSelected}
                value={autoCompleteTagValue}
                inputValue={autoCompleteTagInputValue}
                onInputChange={autoCompleteTagInputChange}
              />
            </Grid.Col>
            {!isContributorOnly && (
              <Grid.Col
                md={12}
                width={12}
                className="mb-2 mt-1 align-items-end d-flex"
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowAddTag(true);
                  }}
                >
                  Create New Tag
                </Button>
              </Grid.Col>
            )}
          </>
        )}
        {showAddTag && (
          <LynxDialog
            dividers
            hasNoActions
            isCloseInHeader
            open={showAddTag}
            handleClose={() => setShowAddTag(false)}
            title={`Add Tag`}
            maxWidth="md"
            dialogContent={
              <TagModal handleAddTag={handleAddTag} isForEvent={true} />
            }
          />
        )}
      </Grid.Row>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
      >
        {selectedTag && <TagQuickCard id={selectedTag.lookupId as number} />}
      </Popover>
    </Dimmer>
  );
};

export default Tags;
