import * as React from "react";
import PageTitle from "./page-title";
import PageSubTitle from "./page-subtitle";
import PageOptions from "./page-options";

interface Props {
  children?: React.ReactNode;
  title?: string;
  subTitle?: string;
  options?: React.ReactNode;
}

const PageHeader: React.FC<Props> = ({
  children,
  title,
  subTitle,
  options,
}) => {
  return (
    <div className="page-header">
      {title && <PageTitle>{title}</PageTitle>}
      {subTitle && <PageSubTitle>{subTitle}</PageSubTitle>}
      {options && <PageOptions>{options}</PageOptions>}
      {children}
    </div>
  );
};

export default PageHeader;
