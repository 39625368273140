import { getUserId } from "./auth";

export const ACCOUNT_LOAD = "ACCOUNT_LOAD";
export const ACCOUNT_LOAD_SUCCESS = "ACCOUNT_LOAD_SUCCESS";
export const ACCOUNT_LOAD_FAIL = "ACCOUNT_LOAD_FAIL";
export function loadAccount() {
  return {
    type: ACCOUNT_LOAD,
    payload: {
      request: {
        url: "/account/" + getUserId(),
      },
    },
  };
}

export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_FAIL = "ACCOUNT_UPDATE_FAIL";
export function updateAccount(account) {
  delete account.organization;

  return {
    type: ACCOUNT_UPDATE,
    payload: {
      request: {
        method: "PUT",
        url: "/account",
        data: account,
      },
    },
  };
}
