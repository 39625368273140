const { apiService } = require("../apiService");

const BASE_ROUTE = "/lookups";

const lookupsEndpoint = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getLookups: builder.query({
      query: (args) => {
        return {
          url: `${BASE_ROUTE}`,
          params: {
            lookupType: args.lookupType,
            bypassFilter: args.bypassFilter || false,
          },
        };
      },
    }),
  }),
});

export const { useGetLookupsQuery } = lookupsEndpoint;
