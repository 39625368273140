import * as React from "react";
import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import { dateUtil } from "../../../services/date-util";
import { ActionStatuses } from "../../../types/enums";
import {
  multipleSelectContainsOperator,
  multipleSelectDoesNotContainOperator,
} from "components/data-grid/multiple-select-operator";
export const getActionColumns = (predefinedLookups = null) => {
  return [
    {
      field: "actionNumber",
      headerName: "Action #",
      width: 100,
      type: "string",
    },
    {
      field: "sourceNumber",
      headerName: "Source",
      width: 100,
      type: "string",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      type: "singleSelect",
      valueOptions: [
        "Pending Approval",
        "Approved",
        "Overdue",
        "Complete",
        "Closed",
      ],
      renderCell: (params) => (
        <div>
          <i
            className={"fe fe-circle "}
            style={{ color: statusColorSwitch(params.value) }}
          />
          &nbsp;
          {params.value}
        </div>
      ),
    },
    {
      field: "title",
      headerName: "Action",
      width: 300,
      type: "string",
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 75,
      type: "singleSelect",
      valueOptions: ["P1", "P2", "P3", "P4", "P5"],
    },

    {
      field: "assignedToUserFullName",
      headerName: "Assigned To",
      width: 150,
      type: "singleSelect",
      lookup: "users",
      lookupValues: predefinedLookups.users,
      valueField: "id",
      labelField: "fullName",
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 110,
      type: "date",
      valueGetter: (params) => {
        return dateUtil.convertDateOnlyToLocal(params.value);
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: 300,
      type: "string",
      disabled: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      type: "singleSelect",
      lookup: "actionCategory",
    },

    {
      field: "evidenceRequired",
      headerName: "Evidence Required",
      width: 150,
      type: "singleSelect",
      lookup: "actionEvidenceRequired",
    },
    {
      field: "closedDateTime",
      headerName: "Closed Date",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        return dateUtil.convertDateTimeToLocal(params.value);
      },
    },

    {
      field: "assetName",
      headerName: "Asset Name",
      width: 150,
      type: "singleSelect",
      lookup: "assets",
      valueField: "id",
      labelField: "name",
    },
    {
      field: "dueInDays",
      headerName: "Due In Days",
      width: 150,
      type: "number",

    },

    {
      field: "assetNames",
      headerName: "Associated Assets",
      width: 150,
      type: "singleSelect",
      lookup: "assets",
      valueField: "id",
      labelField: "name",

      sortable: false,
      filterOperators: [
        ...multipleSelectContainsOperator,
        ...multipleSelectDoesNotContainOperator,
      ],
    },
  ];
};

export const statusColorSwitch = (status) => {
  switch (_.toLower(status)) {
    case _.toLower(ActionStatuses.PendingApproval):
    case _.toLower(ActionStatuses.PendingVerification):
      return lynxColors.harvestOrange;
    case _.toLower(ActionStatuses.Overdue):
      return lynxColors.error;

    default:
      return lynxColors.primary;
  }
};
