import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  getActions,
  getActionsExportAsXLSX,
} from "../../../services/action-service";
import { getActionLookups } from "../../../services/lookup";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import EventQuickView from "./../events/event-quick-view";
import { getActionColumns } from "./action-columns";
import { ActionMobileCard } from "./action-mobile-card";
import { useSelector } from "react-redux";
import { getEvent } from "services/events";
import { getIncident } from "services/incident-service";
import { Dimmer } from "components/lynx-components";
import IncidentQuickView from "../incidents/incident-quick-view";

export function ActionList(props) {
  const [columns, setColumns] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkedEntity, setLinkedEntity] = useState({});

  const handleActionNumberClick = (row) => {
    props.history.push(`/actions/${row.id}`);
  };

  const handleSourceNumberClick = (e, data) => {
    if (data.linkedEventId) {
      getEvent(data.linkedEventId).then((res) => {
        setLinkedEntity(res.data);
      });
    }
    if (data.linkedIncidentId) {
      getIncident(data.linkedIncidentId).then((res) => {
        setLinkedEntity(res.data);
      });
    }
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLinkedEntity({});
  };

  var organization = useSelector((state) => state.organization);
  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };
  const open = Boolean(anchorEl);
  const id = open ? "linked-entity-popover" : undefined;
  useEffect(() => {
    if (!organization.isLoading) {
      let newColumns = [...getActionColumns(predefinedLookups)];
      let colIndex = newColumns.findIndex((x) => x.field == "actionNumber");
      newColumns[colIndex].renderCell = (params) => {
        return (
          <Button
            variant="text"
            onClick={() => handleActionNumberClick(params.row)}
          >
            {params.value}
          </Button>
        );
      };

      let colIndex2 = newColumns.findIndex((x) => x.field == "sourceNumber");
      newColumns[colIndex2].renderCell = (params) => {
        const data = params.row;
        return (
          <Button
            variant="text"
            aria-describedby={id}
            onClick={(e) => handleSourceNumberClick(e, data)}
          >
            {params.value}
          </Button>
        );
      };
      setColumns(newColumns);
    }
  }, [organization.isLoading]);

  return (
    <>
      <LynxDataGrid
        enableSavedFilters
        columns={columns}
        getDataFunction={getActions}
        localStorageName="actions"
        entityName="Action"
        mobileCard={ActionMobileCard}
        getLookupsFunction={getActionLookups}
        handleEventsExportXLSX={getActionsExportAsXLSX}
        {...props}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Dimmer active={_.isEmpty(linkedEntity)} loader>
          {_.isEmpty(linkedEntity) && (
            <div style={{ height: 50, width: 50 }}></div>
          )}
          {!_.isEmpty(linkedEntity) && linkedEntity.eventNumber && (
            <EventQuickView
              event={linkedEntity}
              handleClose={handleClose}
              noEditButton
            />
          )}
          {!_.isEmpty(linkedEntity) && linkedEntity.incidentNumber && (
            <IncidentQuickView
              incident={linkedEntity}
              handleClose={handleClose}
            />
          )}
        </Dimmer>
      </Popover>
    </>
  );
}
