import React from "react";
import cn from "classnames";

interface Props extends React.HTMLProps<HTMLTableRowElement> {
  children?: React.ReactNode;
  className?: string;
}

const TableRow: React.FC<Props> = ({ className, children, ...props }) => {
  const classes = cn(className);
  return (
    <tr className={classes} {...props}>
      {children}
    </tr>
  );
};

TableRow.displayName = "Table.Row";

export default TableRow;
