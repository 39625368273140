import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { FC, useState } from "react";
import { Form } from "components/lynx-components";
import { MultiSelect } from "./../../form-controls/multi-select";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useSelector } from "react-redux";
import { sendEmail } from "../../../services/email-service";
import _ from "lodash";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
import useAlert from "hooks/useAlert";
import { EventDto, RootState, UserDto } from "types";

interface EventEmailModalProps {
  open: boolean;
  handleClose: () => void;
  event: EventDto;
}

const EventEmailModal: FC<EventEmailModalProps> = ({
  open,
  handleClose,
  event,
}) => {
  const account = useSelector((state: RootState) => state.account);
  const { users } = useSelector((state: RootState) => state.lookups);
  const [message, setMessage] = useState<string>("");
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [usersError, setUsersError] = useState<string>("");
  const { showAlert } = useAlert();

  const handleSendEmail = () => {
    if (_.isEmpty(selectedUsers)) {
      setUsersError("Recipients is required.");
      return;
    } else {
      setUsersError("");
    }

    const messageToSend = {
      to: selectedUsers.map((x) => x.email),
      subject: `[LYNX] ${account.firstName} ${account.lastName} shared event ${event.eventNumber} with you`,
      body: `<div>${account.firstName} ${
        account.lastName
      } shared an event with you.
      <a href="${window.location.href}">${window.location.href}</a>
      <br/>Event Number: ${event.eventNumber}
      <br/>Event Category: ${event.eventCategory}
      ${
        event.eventSubCategory
          ? `<br/>Event Subcategory: ${event.eventSubCategory}`
          : ""
      }
      ${message ? `<hr>${message}` : ``}
      </div>`,
    };

    sendEmail(messageToSend).then(() => {
      showAlert("success", "Email sent.");
      handleClose();
    });
  };

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedUsers(e.target.value as any[]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Email Event</DialogTitle>
      <DialogContent>
        <Form.Group label="Select Recipients" id="emailUsersInput" isRequired>
          <MultiSelect
            name="selectedUsers"
            id="selectedUsers"
            onChange={handleChange}
            value={selectedUsers}
            dropdownValues={users}
            key="id"
            label="fullName"
            open={dropdownOpen}
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
            error={usersError}
          />
        </Form.Group>
        <Form.Group label="Recipients will see the event number, event category / subcategory, a link to the event, and your message.">
          <LynxTextArea
            name="emailBody"
            onChange={(e: any) => setMessage(e.target.value)}
            value={message}
          />
        </Form.Group>
      </DialogContent>
      <DialogActions className="pt-0">
        <Button variant="contained" className="mr-1 mb-2" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className="mr-4 mb-2"
          onClick={handleSendEmail}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventEmailModal;
