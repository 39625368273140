import { History } from "history";
export const navigateTo = (
  history: History,
  path: string,
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  if (path) {
    history.push(path);
  }
};
