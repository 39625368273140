import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEnergyLogExportAsXLSX, getEnergyLogs } from "../../../services/energy-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { energyLogColumns } from "./energy-log-columns";
import { EntityTypes } from "../../../types/enums";
import { transformCustomFieldDefsToColumnDefs } from "./../../../services/custom-fields-service";
import { EnergyLogMobileCard } from "./energy-log-mobile-card";
import { getEnergyLogLookups } from "../../../services/lookup";

export function EnergyLogList(props) {
  var account = useSelector((state) => state.account);
  const [columns, setColumns] = useState([]);
  const [eventCustomFields, setEventCustomFields] = useState(null);
  const { users } = useSelector((state) => state.lookups);
  const predefinedLookups = { users: users };
  useEffect(() => {
    let newColumns = [...energyLogColumns(predefinedLookups)];

    let colIndex = newColumns.findIndex((x) => x.field == "energyLogNumber");
    newColumns[colIndex].renderCell = (params) => {
      return (
        <Button
          variant="text"
          className="energy-log-number-button"
          onClick={() => handleEnergyLogNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [energyLogColumns]);

  useEffect(() => {
    getCustomFields(EntityTypes.EnergyLog)
      .then((res) => {
        let eventCustomFields = res.data;
        setEventCustomFields(eventCustomFields);
      })
      .catch((err) => {
        setColumns(energyLogColumns(predefinedLookups));
      });
  }, []);

  useEffect(() => {
    if (eventCustomFields != null && !account.isLoading) {
      let newColumns = [
        ...energyLogColumns(predefinedLookups),
        ...transformCustomFieldDefsToColumnDefs(eventCustomFields),
      ];
      setColumns(newColumns);
    }
  }, [eventCustomFields, account.isLoading]);

  const navigateToAddEnergyLog = () => {
    props.history.push(`/add-energy-log`);
  };

  const handleEnergyLogNumberClick = (row) => {
    props.history.push(`/energy-logs/${row.id}`);
  };

  return (
    <>
      <LynxDataGrid
        enableSavedFilters
        columns={columns}
        getDataFunction={getEnergyLogs}
        title="Energy Logs"
        localStorageName="energyLogs"
        getLookupsFunction={getEnergyLogLookups}
        entityName={EntityTypes.EnergyLog}
        customFields={eventCustomFields}
        addButtonAction={navigateToAddEnergyLog}
        addButtonText="Add Energy Log"
        mobileCard={EnergyLogMobileCard}
        handleEventsExportXLSX={getEnergyLogExportAsXLSX}
        {...props}
      />
    </>
  );
}
