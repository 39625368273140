import React, { useEffect, useRef } from "react";
const classNames = require("classnames");
export function LynxTextArea(props) {
  var className = props.className,
    name = props.name,
    valid = props.valid,
    tick = props.tick,
    invalid = props.invalid,
    cross = props.cross,
    error = props.error,
    placeholder = props.placeholder,
    defaultValue = props.defaultValue,
    value = props.value,
    disabled = props.disabled,
    rows = props.rows,
    children = props.children,
    onChange = props.onChange,
    onBlur = props.onBlur,
    onFocus = props.onFocus,
    onClick = props.onClick,
    onMouseEnter = props.onMouseEnter,
    onMouseLeave = props.onMouseLeave,
    onPointerEnter = props.onPointerEnter,
    onPointerLeave = props.onPointerLeave;

  var classes = classNames(
    "form-control",
    {
      "is-valid": valid,
      "state-valid": tick,
      "is-invalid": invalid || !!error,
      "state-invalid": cross || !!error,
    },
    className
  );
  var feedback = error || props.feedback;

  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
  };

  useEffect(() => {
    if (props.autoResize) {
      resizeTextArea();
    }
  }, [props.value]);

  var contents = React.createElement(
    React.Fragment,
    null,
    React.createElement("textarea", {
      className: classes,
      name: name,
      placeholder: placeholder,
      defaultValue: defaultValue,
      value: value || children,
      disabled: disabled,
      rows: rows,
      onChange: onChange,
      onBlur: onBlur,
      onClick: onClick,
      onFocus: onFocus,
      onMouseEnter: onMouseEnter,
      onMouseLeave: onMouseLeave,
      onPointerEnter: onPointerEnter,
      onPointerLeave: onPointerLeave,
      ref: textAreaRef,
    }),
    feedback &&
      React.createElement("span", { className: "invalid-feedback" }, feedback)
  );

  return contents;
}
