import * as React from "react";

interface Props {
  children?: React.ReactNode;
}

const PageMain: React.FC<Props> = ({ children }) => {
  return <div className="page-main">{children}</div>;
};

export default PageMain;
