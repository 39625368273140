import * as React from "react";

interface Props {
  children?: React.ReactNode;
}

const PageSubTitle: React.FC<Props> = ({ children }) => {
  return <div className="page-subtitle">{children}</div>;
};

PageSubTitle.displayName = "Page.SubTitle";

export default PageSubTitle;
