import * as React from "react";
import cn from "classnames";

interface Props {
  children?: React.ReactNode;
  className?: string;
  aside?: string;
}

const FormLabel: React.FC<Props> = ({ className, aside, children }) => {
  const classes = cn("form-label", className);

  return (
    <label className={classes}>
      {aside && <span className="form-label-small">{aside}</span>}
      {children}
    </label>
  );
};

export default FormLabel;
